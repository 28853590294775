import React, { useRef } from "react";
import "../../_dist/EsdCompCorporateBoardMobile.css";
import EsdCompUpLayeredSection from "./EsdCompUpLayeredSection";


// Define the props interface for EsdCompCorporateMobile
interface CorporateMobileProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompCorporateMobile = ({ ...props }: CorporateMobileProps) => {
//--------------------------------------------------------------------------------
//define reference for scrolling
const sectionsRef = useRef<Array<HTMLDivElement | null>>([]);
//--------------------------------------------------------------------------------

  return (
    <section
      {...props}
      className={`ea-content-mobile-container ${
        props.className ? props.className : ""
      }`}>
      <EsdCompUpLayeredSection ref={(el) => (sectionsRef.current[0] = el as HTMLDivElement)}  sectionsRef={sectionsRef} variant="Operational Territory" screen={"mobile"} />
      <EsdCompUpLayeredSection ref={(el) => (sectionsRef.current[1] = el as HTMLDivElement)}  sectionsRef={sectionsRef}  variant="Managerial Territory" screen={"mobile"} />
      <EsdCompUpLayeredSection ref={(el) => (sectionsRef.current[2] = el as HTMLDivElement)}  sectionsRef={sectionsRef} variant="Executive Territory" screen={"mobile"} />
      <EsdCompUpLayeredSection ref={(el) => (sectionsRef.current[3] = el as HTMLDivElement)}  sectionsRef={sectionsRef} variant="Strategy Execution and Supervision (SES)" screen={"mobile"} />
      <EsdCompUpLayeredSection ref={(el) => (sectionsRef.current[4] = el as HTMLDivElement)}  sectionsRef={sectionsRef} variant="Strategy Planning and Oversight (SPO)" screen={"mobile"} />
    </section>
  );
};

export default EsdCompCorporateMobile;
