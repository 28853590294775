import { useContext, useEffect, useState } from "react";
import EsdCompNavbar from "../communs/EsdCompNavbar";
import { DataContext } from "../../../utils/SwitchedData";
import EsdCompLanguageButton from "../../../components/buttons/EsdCompLanguageButton";
// import { useNavigate } from "react-router-dom";
// import { refContext } from "../layouts/MainLayout";
const EsdCompNavbarFeature = () => {
   // Access to the values setData  that is passed as a value to the provider.
    const {setDataState } = useContext(DataContext);

    //----------------State Language--------------------------------------------------
    const [language, setLanguage] = useState(localStorage.getItem('language') || "English");
    useEffect(() => {
      localStorage.setItem('language', language);
    }, [language]);

    //@Desc-Used to switch language---------------------------------------------------
    const LanguageSwitch = (language: string) => {
      switch (language) {
        case "English":
          return (
            <EsdCompLanguageButton className="navigation-bar-language-btn"
              onClick={() => {
                setLanguage("French");
               if(setDataState) {setDataState("French")}
              }}>
              EN
              </EsdCompLanguageButton>
          );
        case "French":
          return (
            <EsdCompLanguageButton className="navigation-bar-language-btn"
              onClick={() => {
                setLanguage("English");
                if(setDataState) {setDataState("English")}
              }}>
            FR
            </EsdCompLanguageButton>
          );
        default:
          return (
            <EsdCompLanguageButton className="navigation-bar-language-btn"
        
              onClick={() => {
                setLanguage("French");
                if(setDataState) {setDataState("French")}
              }}>
          EN
          </EsdCompLanguageButton>
          );
      }
    };
    //-----------------------------------------------------------------------------
    //use navigate-----------------------------------------------------------------
    // const navigate = useNavigate();
    //-----------------------------------------------------------------------------
//  const myTopRef = useContext(refContext); //use context for reference to the top

//  const handleScrollToTop = () => {
//   navigate("/");
//   if (myTopRef && myTopRef.current) {
//     myTopRef.current.scrollIntoView({ behavior: 'smooth' });
//   }
// };
    //----------------------------------------------------------
  return (
    
      <EsdCompNavbar  language={language} LanguageSwitch={LanguageSwitch} />
  
  );
};

export default EsdCompNavbarFeature;
