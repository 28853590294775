import React, { useContext } from "react";
import EsdCompDivider from "../divider/EsdCompDivider";
import "../../_dist/EsdCompCarousselItem.css";
import { DataContext } from "../../utils/SwitchedData";

interface EsdCompCarousselItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  variant: "aymen-chatti" | "fadwa-gmiden" | "ala-bahri" | "khalil-bhy";
}

const EsdCompCarousselItem = ({ ...props }: EsdCompCarousselItemProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  //switch case for the content of the caroussel items-----------
  const contentSwitch = (variant: string) => {
    switch (variant) {
      case "ala-bahri": // firt case if the variant is "ala-bahri"--------------------------//
        return (
          <div
            title="Photo by Martin Adams in Unsplash"
            {...props}
            className={`caroussel-item-container ${props.variant} ${
              props.className ? props.className : ""
            }`}
          >
            <div className="caroussel-item-overlay">
              <header className="caroussel-item-header">
                <EsdCompDivider
                  className="caroussel-item-divider caroussel-item-divider-left"
                  line={"horizontal"}
                />
                <h2 className="caroussel-item-title">
                  {" "}
                  {data?.team.item_1.h_2}{" "}
                </h2>
                <EsdCompDivider
                  className="caroussel-item-divider"
                  line={"horizontal"}
                />
              </header>
              <h3 className="caroussel-item-subtitle">
                {data?.team.item_1.h_3}
              </h3>
              <p className="caroussel-item-paraghraphe">
                {data?.team.item_1.p_1}{" "}
                <span className="caroussel-item-span">
                  {data?.team.item_1.span_1}
                </span>{" "}
                {data?.team.item_1.p_2}
                <span className="quote-carousel">
                  {data?.team.item_1.span_2}
                </span>
                {data?.team.item_1.p_4}
              </p>
            </div>
          </div>
        );
      case "khalil-bhy": // firt case if the variant is "khalil-bhy"--------------------------//
        return (
          <div
            title="Photo by Artem Gavrysh in Unsplash"
            {...props}
            className={`caroussel-item-container ${props.variant} ${
              props.className ? props.className : ""
            }`}
          >
            <div className="caroussel-item-overlay">
              <header className="caroussel-item-header">
                <EsdCompDivider
                  className="caroussel-item-divider caroussel-item-divider-left"
                  line={"horizontal"}
                />
                <h2 className="caroussel-item-title">
                  {" "}
                  {data?.team.item_2.h_2}
                </h2>
                <EsdCompDivider
                  className="caroussel-item-divider"
                  line={"horizontal"}
                />
              </header>
              <h3 className="caroussel-item-subtitle">
                {data?.team.item_2.h_3}
              </h3>
              <p className="caroussel-item-paraghraphe">
                {data?.team.item_2.p_1}
                <span className="quote-carousel">
                  {data?.team.item_2.span_1}
                </span>{" "}
                <span className="caroussel-item-span">
                  {data?.team.item_2.span_2}
                </span>
                {data?.team.item_2.p_2}
              </p>
            </div>
          </div>
        );
      case "fadwa-gmiden": // firt case if the variant is "fadwa-gmiden"--------------------------//
        return (
          <div
            title="Photo by frame harirak in Unsplash"
            {...props}
            className={`caroussel-item-container ${props.variant} ${
              props.className ? props.className : ""
            }`}
          >
            <div className="caroussel-item-overlay">
              <header className="caroussel-item-header">
                <EsdCompDivider
                  className="caroussel-item-divider caroussel-item-divider-left"
                  line={"horizontal"}
                />
                <h2 className="caroussel-item-title">
                  {" "}
                  {data?.team.item_3.h_2}
                </h2>
                <EsdCompDivider
                  className="caroussel-item-divider"
                  line={"horizontal"}
                />
              </header>
              <h3 className="caroussel-item-subtitle">
                {data?.team.item_3.h_3}
              </h3>
              <p className="caroussel-item-paraghraphe">
                {data?.team.item_3.p_1}
                <span className="quote-carousel">
                  {data?.team.item_3.span_1}
                </span>{" "}
                <span className="caroussel-item-span">
                  {data?.team.item_3.span_2}
                </span>
                {data?.team.item_3.p_2}
                <span className="quote-carousel">
                  {data?.team.item_3.span_3}
                </span>
                »
              </p>
            </div>
          </div>
        );
      case "aymen-chatti": // firt case if the variant is "aymen-chatti"--------------------------//
        return (
          <div
            title="Photo by Rezaul Karim in Unsplash"
            {...props}
            className={`caroussel-item-container ${props.variant} ${
              props.className ? props.className : ""
            }`}
          >
            <div className="caroussel-item-overlay">
              <header className="caroussel-item-header">
                <EsdCompDivider
                  className="caroussel-item-divider caroussel-item-divider-left"
                  line={"horizontal"}
                />
                <h2 className="caroussel-item-title">
                  {" "}
                  {data?.team.item_4.h_2}
                </h2>
                <EsdCompDivider
                  className="caroussel-item-divider"
                  line={"horizontal"}
                />
              </header>
              <h3 className="caroussel-item-subtitle">
                {data?.team.item_4.h_3}
              </h3>
              <p className="caroussel-item-paraghraphe">
                {data?.team.item_4.p_1}
                <span className="caroussel-item-span">
                  {data?.team.item_4.span_1}
                </span>
                {data?.team.item_4.p_2}
                <span className="quote-carousel">
                  {data?.team.item_4.span_2}
                </span>{" "}
                {data?.team.item_4.p_3}
              </p>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  //--------------------------------------------------------------
  return <>{contentSwitch(props.variant)}</>;
};

export default EsdCompCarousselItem;
