export const dataEN = {
  /******************************************************ERATHIS DATA*******************************************************************/
 
  //navBar------------------------------------------------------------------------------------------------------------------------------
  navBar: {
    executiveArchitecture: "Executive Architecture",
    partnerships: "Partnerships",
    featured: "Featured",
    contact: "Contact us",
    about: "About Us",
  },

  //Erathis Hero Card------------------------------------------------------------------------------------------------------------------
  heroCard: {
    h_1: "ERATHIS",
    h_2: "The Sense of Simplicity",
    h_3: "",
    text_button: "Contact Us",
  },

  //Erathis simple card-----------------------------------------------------------------------------------------------------------------
  simpleCard: {
    title_1: "Humility Bound",
    title_2: "Efficiency-Driven",
    title_3: "Venture Guided",
    p_1: {
      span_1: "",
      part_1:
        "Our proposals are devoid of false promises. Take a bet on our passion and expertise.",
      span_2: "",
      part_2: "",
      
    },
    p_2: {
      part_1:
        "Know-how is asking questions, identifying problems to  naturally design sustainable solutions.",
      span_1: "",
    },
    p_3: {
      span_1: "",
      part_1:
        "Marked trails are just as important as undiscovered paths. Your problems fascinate us.",
      span_2: "",
    },
  },

  //Erathis offers Section-----------------------------------------------------------------------------------------------------------------------
  offers: {
    h_1: "Our Services",
    title: {
      title_1: "Supporting Leaders",
      title_2: "Training Executive Forces",
      title_3: "Building Corporates",
      title_4: "Supervising Projects",
    },
    p_1: {
      part_1: "",
      span_1: "Governance, Security, Communication with Finance, Brand, Flow of Influence, IEP Architectures (Intelligence, Experience, Production). ",
      part_2:
        " We Support your Leaders in developing a healthy aggregation of these fields in order to promote healthy decision-making in creating long and short-term strategies.",
    },
    p_2: {
      part_1:
        "We Support your Executives in",
      span_1: " understanding Crossfield communication, financial language and multi-stakeholder prioritization. ",
      part_2:
        "Figuring out the right executive position types and how to strengthen them with the best teams considering current skill compositions.",
    },
    p_3: {
      part_1:
        "Together we will incorporate the knowledge you got so far into the growth of a healthy and synergetic",
      span_1: " Corporate Cell",
      part_2: ". We have designed ",
      span_2: "Insight Architecture ",
      part_3: "as a means to manage the flow of information and ",
      span_3: "the dispatching of critical insights ",
      part_4:
        "in your organisation as well as the oversight of the global Experience you are providing in a way that protects your system in the long term.",
    },
    p_4: {
      part_1:
        "After an in-depth discussion of your goals, we will establish the needs and boundaries of your project. Together we will implement customised methods to establish a Strategic",
      span_1: " Decision Making ",
      part_2: "log, proper ",
      span_2: "Oversight ",
      part_3: "and an adequately flexible and adaptive ",
      span_3: "Piloting",
      part_4: ".",
    },
  },

  //Services Descriptive Card------------------------------------------------------------------------------------------------------------------
  descriptiveCard: {
    h_1: "ERATHIS",
    p1: "With a mission at heart and a vision in mind, an Organisation is an aggregation of knowledge and skills intended to provide service. It is a common misbelief that IT is in itself Major and Sufficient when its true value lies in supporting Intuitive Synergetic Executive Decision Making.",
    p2:"A good choice isn't about individual skill, Decision-makers must be capable of expanding their understanding in a cross-dimensional way and define priorities with a logic of steadiness and sequence in order to unlock the advantages necessary to boost their specific strategies. Successful organisations, understand the value of human capital and are capable of designing systems that encompass the most intuitive protocols for solid governance, intelligence gathering and providing the best-expected service experiences for their targeted customers.",
    p3:"With Erathis, cooperation comes first, as solutions must feel natural with a sense of simplicity. That's where we let our skills take over, we find our shine in aggregating requirements, mediating strategy sessions and composing the right teams for execution.",
  },

  //Erathis Expertise Section----------------------------------------------------------------------------------------------------------------------
  expertise: {
    h_1: "Our Expertise",
    title: {
      title_1: "Digitalization Strategy",
      title_2: "Product Management Strategy",
      title_3: "Design  Brands Management Strategy ",
      title_4: "Insights Architecture",
      title_5: "Workforce Transformation Strategy",
      title_6: "SaaS Products",
    },
    p_1: "Incorporate or strategize with comprehensive approach for better digital transformation and business outcomes.",
    p_2: "Strategize your product management process for fluid team collaborations enhancing business growth.",
    p_3: "We provide meaningful holistic Brand Communication Strategies for high-impact and optimized results.",
    p_4: "A diagnosis with a holistic ADCC (accessible, durable, comfortable, clear) approach for experience architecture .",
    p_5: "We evaluate your current tools & systems providing  end-to-end advisory and implementation services.",
    p_6: "A set of digital products that supports us in providing the best experience and most adequate results.",
  },

  //Erathis team(Carousel)------------------------------------------------------------------------------------------------------------------
  team: {
    h_1: "Our Team",
    item_1: {
      h_2: "Insights & Oversight Officer - ALAEDDINE BAHRI",
      h_3: "Experience Designer, Graphic Designer, Photographer & Insight Officer",
      p_1: "«The wise ",
      span_1: "Innovator ",
      p_2: "does at once what the fool does finally.",
      span_2: " (N.M.)",
      p_4: "»",
    },
    item_2: {
      h_2: "Chief Insights Officer - Khalil BEL HAJ YAHIA",
      h_3: "UX Architect & Growth Specialist",
      p_1: " «To understand is to Transform what is ",
      span_1: "(J.K.)",
      span_2: "and to create is to defy what has yet to exist",
      p_2: ".»",
    },
    item_3: {
      h_2: "Chief Executive Officer - FADWA GMIDEN",
      h_3: "CEO & Software Engineer",
      p_1: "«Small projects need much more help than great ",
      span_1: "(D.A.)",
      span_2: "Therefore",
      p_2: ", If you can’t do great things, do small things in a great way. ",
      span_3: "(N.H.)",
      p_4: "»",
    },
    item_4: {
      h_2: "Chief Operations Officer - AYMEN CHATTI",
      h_3: "Branch Director & Operations Specialist",
      p_1: "«The desire for ",
      span_1: "Excellence",
      p_2: ", like the thirst for riches, increases ever with the acquisition of it. ",
      span_2: "(L.S.) ",
      p_3: "»",
    },
  },

  //Erathis Goals---------------------------------------------------------------------------------------------------------------------------
  goals: {
    h_1: "Our Goals",
    title: {
      title_1: "By 2027",
      title_2: "By 2031",
      title_3: "By 2037",
    },
    p_1: "We’re recognized for establishing new standards of Executive Architecture for all types of organisations.",
    p_2: "We forge  ecosystems that guide leaders in the face of the unexpected and prevent decision-making failures.",
    p_3: "We establish cross-continental ecosystems to improve Mercantile collaborations and Expertise Distribution.",
  },

  //Erathis Mission-------------------------------------------------------------------------------------------------------------------------
  mission: {
    h_1: "Our Mission",
    title: "By 2041",
    p: "To bring together Science, Arts, Technology, Craftsmanship, and all fields of the professional world to create large scale projects that will benefit and safeguard humanity. ",
  },

  //Erathis Connecting-----------------------------------------------------------------------------------------------------------------------
  connecting: {
    h_1: "Connecting",
    title: "We can be part of your adventure",
    p: " We are diverse, authentic, and sincerely committed to building a better world.",
    text_button: "Get in touch",
  },

  //Erathis About Us---------------------------------------------------------------------------------------------------------------------------
  aboutUs: {
    h_1: "About us",
    p: "Erathis supports CEOs in managing & delegating the different responsibilities they have and accompanies them in composing and training a solid executive team to build a healthy corporate. We are constantly aiming to simplify and fix Day-to-Day Operations while facilitating access to unique profiles. Our approach Neurodiverse inclusive and completely adaptive.",
    location: "Main Locations",
    p_1: "Western Europe, Belgium, Brussels",
    p_2: "Northen Africa, Tunisia, Gabes",
    follow: "Follow us",
    linkedin: "Linkedin",
    instagram: "Instagram",
    twitter: "Twitter",
    facebook: "Facebook",
    copyRight: "Ⓒ ERATHIS CONSULTING  All rights reserved.",
  },

  //Erathis Contact Us--------------------------------------------------------------------------------------------------------------------------
  contactUs: {
    h_2: "With Sense and Simplicity",
    name: "Name",
    email: "Email",
    company: "Company",
    subject: "Subject",
    h_1: "Your Message",
    message: "Type your message here",
    text_button: "Send",
  },

  //Erathis Featured-----------------------------------------------------------------------------------------------------------------------------
  featured: {
    h_1: "KoreWaKore",
    p_1: "We provide a Values-Purpose-Culture based e-Tavern for people and organisations to gather around Meaningful Projects and accurately Benchmark Career KPIs and Ecosystems Evolution.",
    p_2: "Sit still for Industry insights, Individual or Group Career Planning, Independent and Organisations showcases, Smart Dynamic Portfolios for both People and Entities.",
    li_1: "Connect",
    li_2: "Collaborate",
    li_3: "Benchmark",
    title_1: "KoreWaKore",
    title_2: "Supporting resolutions",
    text_button: "COMMING SOON",
  },

  //Partnerships section erathis data-----------------------------------------------------------------------------------------------------
  partnerships: {
    h_1: "ERATHIS",
    h_2: "Exploring Partnerships and More...",
    part_1: {
      h_1: "What we are looking to do",
      part_1: "Erathis supports ",
      span_1: "organisations and leaders",
      part_2: "in adopting ",
      span_2: "sustainable and healthy work methodologies",
      part_3: ". Our aim is to build a strong and diversified",
      span_3: " partnership ecosystem ",
      part_4:
        "under a framework of a unified insights relay and architecture. In order to reach that, we wish to focus on energising and connecting different work sectors through dynamic communication means. By gathering insightful information, we wish to promote ",
      span_4:
        "fair competition and better market share distribution for our partners.",
      part_5:
        "By unifying around a stable and strong network, Erathis’s Partnerships for Sustainability compose an ecosystem that acts as a ",
      span_5:
        "sentinel for ecological, political, social, and economic hazards.",
    },
    part_2: {
      h_1: "Basics of Partnership",
      p: "Here we will lay down some of the major collaboration points that we will discuss with you. This non-exhaustive list is a good point of reference to prepare for the meeting with our agents.",
    },
    generalities: {
      h_1: "Generalities",
      collaboration: {
        h_2: "About Collaborations",
        subject: {
          h_3: "We will discuss the following subjects",
          li_1: "If we share similar visions and goals.",
          li_2: "In what ways are you willing to change and what outcome do you want to achieve?",
          li_3: "We will discuss your company culture and work ethics.",
          li_4: "We will assess the clarity of your brand and overall communication.",
          li_5: "Mandatory Corporate Standards package.",
        },
        entity: {
          h_3: "Be aware that our company is against dealing with the following entities",
          li_1: "Entities that lack legal transparency.",
          li_2: "Entities that promote hate or are supportive of socially divisive organisations (Xenophobia, Anti-semitism, Terrorism…).",
          li_3: "Entities with financial opacity and illegal dealings.",
          li_4: "Entities that put profit before the safety and integrity of people.",
        },
      },
      goals: {
        h_2: "Collaborations Goals",
        h_3: "We will discuss the following subjects",
        li_1: "Joint ventures (projects in which we will collaborate) risk/profit distribution.",
        li_2: "Partner Services rates.",
        li_3: "Use of intangible assets for PR & business like brand, access to customers, etc…",
        li_4: "Access to opportunity through recommendation (client projects, tenders).",
      },
      media: {
        h_2: "PR-Media",
        consensus: {
          h_3: "Web and Social Media Consensus",
          paragraph:
            "During our partnership we will discuss a number of points regarding social media posting and sharing of information, as well as how we will be addressing each other on the web.",
        },
        validation: {
          h_3: "No posting without validation",
          li_1: "One agent from each side of the partnership is designated as partnership liaison.",
          li_2: "The designated partnership liaisons must both validate the social media posts and other web content before posting.",
          li_3: "A Shared task board with the planning of the media management will be used to this effect.",
          li_4: "There will be an official communication channel opened between both agents.",
          li_5: "Agents can validate Media in a grouped bulk.",
        },
        guideline: {
          h_3: "In compliance with the Erathis Guidelines",
          li_1: "The designated partnership liaisons oversee the creation of specific SEO-tailored articles.",
          li_2: "The designated partnership liaisons oversee the creation and follow-up of Hashtag events.",
        },
        citation: {
          h_3: "Citation",
          li: "Erathis must always be cited as per the guidelines provided by the communication Division.",
        },
        subject: {
          h_3: "We will also discuss the following subjects",
          li_1: "Collaborations points in Media and PR.",
          li_2: "Mutual Brand boundaries and redlines.",
          li_3: "Communication contexts (Leeway, protocols, mutual protection, contact routes, confidentiality).",
        },
      },
      project: {
        h_2: "Project Priority",
        subject: {
          h_3: "We will discuss the following subjects",
          li_1: "Priority to see projects that ",
          span_1: "are related to your field",
          li_2: " as our partner.",
          li_3: "Priority to collaborate with us on projects ",
          span_2: "we have jurisdiction on.",
          li_4: "Open for Shared Training.",
          li_5: "Erathis Packages (whole, part, training,..).",
          li_6: "In the case of training, all partners' “ specific department” are targeted to attend the same training (not mandatory).",
          li_7: " We require the ID Establishment service to be set prior to any yearly planning that would contain a 3 months Package.",
          li_8: "Defining needs and level of project priority.",
          li_9: "On the project: Complexity, budget, timeline.",
          li_10:
            "Level of project priority: how it affects our and our partners’ brand.",
        },
        segmentation: {
          h_3: "Partner segmentation",
          li_1: "Partners segmentation: Type A, B, C, and D.",
          li_2: "A - Simple agreement.",
          li_3: "B - Partial Collaboration Agreement.",
          li_4: "C - Full Collaboration Agreement.",
          li_5: "D - Added Board of Management Service.",
          li_6: "Partner’s needs in terms of projects and offers must be specified clearly.",
          li_7: "Level of priority in terms of Rank goes by Project Compatibility then Segmentation then Seniority.",
        },
      },
    },
    advantages: {
      h_1: "Advantages",
      p: "Widen your ecosystem by getting to know our partnership ecosystem. At Erathis, we care about long-term relationships that focus on access to opportunities and markets beyond short-term monetary value.",
      Competitive: {
        h_2: "Competitive service rates",
        h_3: "We will discuss the following subjects",
        li_1: "A price reduction related to the package choice by these criteria:",
        li_2: "Package Type",
        li_3: "Duration of Mission",
        li_4: "Total price",
        li_5: "Collaboration Advantages with our other Partners",
      },
      Nonfunctional: {
        h_2: "Nonfunctional Advantages",
        h_3: "We will discuss the following subjects",
        li_1: "Access to a shared space for markets or other opportunities.",
        li_2: "Facilitated access to our consolidated knowledge base for Expertises and best practices (KWK).",
        li_3: "Priority access to training, events, etc.",
      },
      contact: {
        h_2: "Liberty of Contact",
        h_3: "We will discuss the following subjects",
        li_1: "Specify the number of Unique Contact Permissions for Opportunity Assessments.",
        li_2: "Specify an advantageous contact schedule for each package chosen.",
      },
      payment: {
        h_2: "Payment Method",
        p: "To be discussed  per partnership type.",
      },
      facilitation: {
        h_2: "Payment Facilitation",
        p: "We will discuss payment conditions for each package and service (amount/period) per partnership type.",
      },
    },
  },

  // Partners cards---------------------------------------------------------------------------------------------------------------------------
  card: {
    h_1: "Our Partners",
    p_1: "African Diaspora Youth Forum in Europe",
    p_2: "DIGINOV - High End Development and Coding",
    p_3: "HIVE12 - Incubation and Coworking Spaces",
    p_4: "DESIGN CODE - Quality Interface Design",
  },

  //Erathis Executive architecture------------------------------------------------------------------------------------------------------------
  executive: {
    span_1: "Exceptional Service -",
    p_1: "BOARD OF MANAGEMENT",
    span_2: "What we do:",
    p_2: "Corporate Architecture Explained",
  },

  //Board of Management----------------------------------------------------------------------------------------------------------------------
  board: {
    descriptiveAside: {
      h_6: "Exceptional Service",
      h_1: "BOARD OF MANAGEMENT",
      p_1: "Board of Management is a custom tailor full-service support for CEOs and/or Execs who are overwhelmed with operational tasks with no time for strategic vision. We guide you in creating a plan for your organisation's continuity and scalability.",
      p_2: "We will assist you in regular strategic meetings in regard to Target focus, Retrospectives, and Pivotings. The service consists of continuous support on optimising the process, identifying the right profiles to alleviate your executive burden, and assessing your value chain.",
      text_button: "Corporate Architecture Explained",
    },
    tabs: {
      li_1: "YOUR PAIN POINTS",
      li_2: "How we help you reach the outcomes you desire",
    },

    //YOUR PAIN POINTS--------------------------------------------------------------------------------------------------------------------------
    painPoints: {
      eaButton: {
        text_1: "Executive Solitude",
        text_2: "Overwork",
        text_3: "Hit the board, Jack!",
        text_4: "Missing Sleep and opportunities",
      },
      paragraph: {
        span_1: "Me, myself, and I:",
        p_1: " When you find yourself taking all decisions related to the vitality of the business and you don’t have anyone around you that can assist you in taking the right measures.",
        span_2: "To delegate or to delegate,",
        p_2: " this is not a question: When you upscale your business and your focus scatters on multiple tasks which prevent you from channeling your energy on strategy. Reminder, Upscaling isn't just about more ops, to upscale correctly is to delegate executive tasks.",
        span_3: "Down the rabbit hole:",
        p_3: " When you become the Jack of all trades you cover for all the different hats required for executive functioning. This turns you into a Joker Card position to put out all the fires without necessarily progressing with your plans.",
        span_4: "Back against the wall:",
        p_4: " Because of the lack of time and focus, not having executive support affects public relationships and partners and prevents you from taking the needed time and clarity of mind to assess potential risks and opportunities.",
      },
    },

    //How we help you reach the outcomes you desire-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    help: {
      p: "We build a robust executive structure that reduces your challenges and provides a great avenue for growth.",
      eaButton: {
        text_1: "Understanding Management & leadership",
        text_2: "Decision-Making and KPIs",
        text_3: "Building the A team",
        text_4: "Solution-oriented mindset",
      },
      p_1: "Define how you lead your business continuity and added value through strategy. Save yourself from over meddling with management needlessly while ensuring your culture is top-notch.",
      p_2: "Build a custom and efficient framework to monitor the growth of your organisation including a carefully designed flow for insights providing the necessary information for optimal decision-making.",
      p_3: "Identify the responsibilities and tasks that can be delegated, find the right fit, and build an optimised process that ensures efficiency and added value.",
      p_4: "Set your focus on growth and what needs to be better with a long-term mindset which prevents weak patchwork fixes.",
    },
  },

  //Corporate Architecture Explained------------------------------------------------------------------------------------------------------------------
  corporate: {
    descriptiveAside: {
      h_6: "What we do:",
      h_1: "Corporate Architecture Explained",
      span_1: "Exceptional Service",
      text_button: "- Board of Management",
    },
    h_1: "An organisation is a layered system of systems",
    p: "It is crucial to understand that each system within an organisation represents territories with particular sets of responsibilities, processes, deliverables, and boundaries. They must communicate smoothly to cohabitate peacefully and synergistically.",
    eaButton: {
      text_1: "Operational Territory",
      text_2: "Managerial Territory",
      text_3: "Executive Territory",
      text_4: "Strategy Execution and Supervision (SES)",
      text_5: "Strategy Planning and Oversight (SPO)",
    },
    p_1: "Operations focus on successfully implementing an added value. It encompasses anyone whose job is to yield a concrete output in which they can exhibit creativity and technical ingenuity within their tasks.",
    p_2: "Just because you have a concrete output, doesn’t mean you have a successful value delivery. Excellent managers are masters in partitioning action plans efficiently and coordinating resources and tasks to make the most out of their teams.",
    p_3: "Execs focus on value creation and capture through strategy and market access. The Exec system in itself contains two separate yet complementary areas.",
    p_4: "Understands boundaries between the different SES Fiefs and impact/risk assessment of decisions.",
    p_5: "Breaks down the strategy into a viable action plan and adequate distribution to management.",
    p_6: "Acts as the main point of contact and negotiation for Partners and Suppliers.",
    p_7: "SPO directs the organisation strategy toward its external ecosystem and potential markets.",
    p_8: "Creates a strategy by turning Vision Goals into quantifiable objectives.",
    p_9: "Creates the right Alert & Insight (A&I) checkpoints.",
    p_10: "Facilitates, Mediates and is the tie-breaker of SES Conflicts.",
    p_11: "Supervises and validate the strategy in motion.",
    p_12: "Dwells outside of the limits of the organisation: Rainmaking, Prospecting and Closing.",
  },
  //message alert Architecture Explained------------------------------------------------------------------------------------------------------------------
 messageAlert:{
  paragraph:"Your message has been successfully sent."
 }
};

 