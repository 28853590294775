import React, { useContext, useRef } from "react";
import "../../../_dist/EsdCompPartnershipComponent.css";
import EsdCompFooterCopyright from "../../../components/EsdCompFooterCopyright";
import EsdCompDivider from "../../../components/divider/EsdCompDivider";
import EsdCompPartnersComponent from "../../../components/EsdCompPartnersComponent";
import ExecuteScrollComponent from "../../../utils/ExecuteScrollComponent";
import { DataContext } from "../../../utils/SwitchedData";
interface PartnershipCompProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}
const EsdCompPartnershipComponent = ({ ...props }: PartnershipCompProps) => {
  // Create a reference using the useRef hook--------------------------------
  const myRef = useRef<HTMLElement>(null);
  //-----------------------------------------------------------------------------
  // Access to the values setData  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  return (
    <>
      <section
        ref={myRef}
        {...props}
        className={`partnership-component-container ${
          props.className ? props.className : ""
        }`}
      >
        <header className="header-partnership">
          <EsdCompDivider line="horizontal" className="partnership-divider" />
          <h1>{data?.partnerships.h_1}</h1>
          <EsdCompDivider line="horizontal" className="partnership-divider" />
        </header>
        <h2>{data?.partnerships.h_2}</h2>
        <section className="partnership-component-box">
          <h1>{data?.partnerships.part_1.h_1}</h1>
          <div className="partnership-pargraph-container">
            <p>
              {data?.partnerships.part_1.part_1}
              <span>{data?.partnerships.part_1.span_1}</span> {data?.partnerships.part_1.part_2}
              <span>{data?.partnerships.part_1.span_2}</span>{data?.partnerships.part_1.part_3}
              <span>{data?.partnerships.part_1.span_3}</span>{data?.partnerships.part_1.part_4}
              <span>
              {data?.partnerships.part_1.span_4}
              </span>{" "}
            </p>
            <p>
            {data?.partnerships.part_1.part_5}
              <span>
              {data?.partnerships.part_1.span_5}
              </span>
            </p>
          </div>
        </section>
        <section className="partnership-component-box">
          <h1>{data?.partnerships.part_2.h_1}</h1>
          <p>
          {data?.partnerships.part_2.p}
          </p>
        </section>
        <section className="partnership-component-box">
          <h1>{data?.partnerships.generalities.h_1}</h1>
          <h2>{data?.partnerships.generalities.collaboration.h_2}</h2>
          <h3>{data?.partnerships.generalities.collaboration.subject.h_3}</h3>
          <ul className="partnership-component-list">
            <li>{data?.partnerships.generalities.collaboration.subject.li_1}</li>
            <li>
            {data?.partnerships.generalities.collaboration.subject.li_2}
            </li>
            <li>{data?.partnerships.generalities.collaboration.subject.li_3}</li>
            <li>
            {data?.partnerships.generalities.collaboration.subject.li_4}
            </li>
            <li>{data?.partnerships.generalities.collaboration.subject.li_5}</li>
          </ul>

          <h3>
          {data?.partnerships.generalities.collaboration.entity.h_3}
          </h3>
          <ul className="partnership-component-list">
            <li>{data?.partnerships.generalities.collaboration.entity.li_1}</li>
            <li>
            {data?.partnerships.generalities.collaboration.entity.li_2}
            </li>
            <li>{data?.partnerships.generalities.collaboration.entity.li_3}</li>
            <li>
            {data?.partnerships.generalities.collaboration.entity.li_4}
            </li>
          </ul>
          <h2>{data?.partnerships.generalities.goals.h_2}</h2>
          <h3>{data?.partnerships.generalities.goals.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.generalities.goals.li_1}
            </li>
            <li>{data?.partnerships.generalities.goals.li_2}</li>
            <li>
            {data?.partnerships.generalities.goals.li_3}
            </li>
            <li>
            {data?.partnerships.generalities.goals.li_4}
            </li>
          </ul>
          <h2>{data?.partnerships.generalities.media.h_2}</h2>
          <h3>{data?.partnerships.generalities.media.consensus.h_3}</h3>
          <p className="partnership-component-specific-paragraph">
          {data?.partnerships.generalities.media.consensus.paragraph}
          </p>
          <h3>{data?.partnerships.generalities.media.validation.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.generalities.media.validation.li_1}
            </li>
            <li>
            {data?.partnerships.generalities.media.validation.li_2}
            </li>
            <li>
            {data?.partnerships.generalities.media.validation.li_3}
            </li>
            <li>
            {data?.partnerships.generalities.media.validation.li_4}
            </li>
            <li> {data?.partnerships.generalities.media.validation.li_5}</li>
          </ul>
          <h3> {data?.partnerships.generalities.media.guideline.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.generalities.media.guideline.li_1}
            </li>
            <li>
            {data?.partnerships.generalities.media.guideline.li_2}
            </li>
          </ul>
          <h3>{data?.partnerships.generalities.media.citation.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.generalities.media.citation.li}
            </li>
          </ul>
          <h3>{data?.partnerships.generalities.media.subject.h_3}</h3>
          <ul className="partnership-component-list">
            <li>{data?.partnerships.generalities.media.subject.li_1}</li>
            <li>{data?.partnerships.generalities.media.subject.li_2}</li>
            <li>
            {data?.partnerships.generalities.media.subject.li_3}
            </li>
          </ul>
          <h2>{data?.partnerships.generalities.project.h_2}</h2>
          <h3>{data?.partnerships.generalities.project.subject.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.generalities.project.subject.li_1}
              <span>{data?.partnerships.generalities.project.subject.span_1}</span>{data?.partnerships.generalities.project.subject.li_2}
            </li>
            <li>
            {data?.partnerships.generalities.project.subject.li_3}
              <span>{data?.partnerships.generalities.project.subject.span_2}</span>
            </li>
            <li> {data?.partnerships.generalities.project.subject.li_4}</li>
            <li>
            {data?.partnerships.generalities.project.subject.li_5}
              <ul className="partnership-component-list-element">
                <li>
                {data?.partnerships.generalities.project.subject.li_6}
                </li>
                <li>
                {data?.partnerships.generalities.project.subject.li_7}
                </li>
              </ul>
            </li>
            <li>
            {data?.partnerships.generalities.project.subject.li_8}
              <ul className="partnership-component-list-element">
                <li>{data?.partnerships.generalities.project.subject.li_9}</li>
                <li>
                {data?.partnerships.generalities.project.subject.li_10}
                </li>
              </ul>
            </li>
          </ul>
          <h3>{data?.partnerships.generalities.project.segmentation.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.generalities.project.segmentation.li_1}
              <ul className="partnership-component-list-element">
                <li>{data?.partnerships.generalities.project.segmentation.li_2}</li>
                <li>{data?.partnerships.generalities.project.segmentation.li_3}</li>
                <li>{data?.partnerships.generalities.project.segmentation.li_4}</li>
                <li>{data?.partnerships.generalities.project.segmentation.li_5}</li>
              </ul>
            </li>
            <li>
            {data?.partnerships.generalities.project.segmentation.li_6}
            </li>
            <li>
            {data?.partnerships.generalities.project.segmentation.li_7}
            </li>
          </ul>
        </section>
        <section className="partnership-component-box">
          <h1>{data?.partnerships.advantages.h_1}</h1>
          <p>
          {data?.partnerships.advantages.p}
          </p>
          <h2>{data?.partnerships.advantages.Competitive.h_2}</h2>
          <h3>{data?.partnerships.advantages.Competitive.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.advantages.Competitive.li_1}
              <ul className="partnership-component-list-element">
                <li> {data?.partnerships.advantages.Competitive.li_2}</li>
                <li> {data?.partnerships.advantages.Competitive.li_3}</li>

                <li> {data?.partnerships.advantages.Competitive.li_4}</li>
                <li> {data?.partnerships.advantages.Competitive.li_5}</li>
              </ul>
            </li>
          </ul>
          <h2> {data?.partnerships.advantages.Nonfunctional.h_2}</h2>
          <h3>{data?.partnerships.advantages.Nonfunctional.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.advantages.Nonfunctional.li_1}
            </li>
            <li>
            {data?.partnerships.advantages.Nonfunctional.li_2}
            </li>
            <li>{data?.partnerships.advantages.Nonfunctional.li_3}</li>
          </ul>
          <h2>{data?.partnerships.advantages.contact.h_2}</h2>
          <h3>{data?.partnerships.advantages.contact.h_3}</h3>
          <ul className="partnership-component-list">
            <li>
            {data?.partnerships.advantages.contact.li_1}
            </li>
            <li>
            {data?.partnerships.advantages.contact.li_2}
            </li>
          </ul>
          <h2>{data?.partnerships.advantages.payment.h_2}</h2>
          <p className="specific-partnership-paragraph">
          {data?.partnerships.advantages.payment.p}
          </p>
          <h2>{data?.partnerships.advantages.facilitation.h_2}</h2>
          <p className="specific-partnership-paragraph">
          {data?.partnerships.advantages.facilitation.p}
          </p>
        </section>
        <footer className="footer-partnership-container">
          <EsdCompPartnersComponent />
          <EsdCompFooterCopyright />
        </footer>
      </section>
      <ExecuteScrollComponent scrollRef={myRef} />
    </>
  );
};

export default EsdCompPartnershipComponent;
