import React, { useContext } from "react";
import "../../_dist/EsdCompEaHeader.css";
import { DataContext } from "../../utils/SwitchedData";

// Define the props interface for EsdComEaHeader
interface EaHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  variant: "board" | "corporate";
}

const EsdComEaHeader = ({ ...props }: EaHeaderProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);

  const contentSwitch = (variant: string) => {
    switch (variant) {
      case "corporate": //  case if the variant is "corporate"--------------------------//
        return (
          <>
            <h1>{data?.corporate.h_1}</h1>
            <p>{data?.corporate.p}</p>
          </>
        );

      case "board": // case if the variant is "board"--------------------------//
        return <p>{data?.board.help.p}</p>;
      default:
        break;
    }
  };

  return (
    <div
      {...props}
      className={`ea-header-container ${
        props.className ? props.className : ""
      } ea-header-${props.variant}`}
    >
      {contentSwitch(props.variant)}
    </div>
  );
};

export default EsdComEaHeader;
