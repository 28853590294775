import React, { useContext } from "react";
import "../../_dist/EsdCompBurgerItemsList.css";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../utils/SwitchedData";
import { useScrollToBottom } from "../../utils/HandleScrollToBottom";

// Define the props interface for EsdCompBurgerItemsList
interface BurgerItemsListProps extends React.HTMLAttributes<HTMLUListElement> {
  id?: string;
  setIsBurgerOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const EsdCompBurgerItemsList = ({
  setIsBurgerOpened,
  ...props
}: BurgerItemsListProps) => {
  //Used for navigate-------------
  const navigate = useNavigate();
  //------------------------------
  //useScroll to botton
  const scrollToButtom = useScrollToBottom();
  //---------------------------------------------------------
  /* 
 @definition: a function used on click of the logo to navigate
  to the home page and scroll to the bottom
@async function: the use of async is for navigate first to the home
 than scroll to the bottom
  */
  const handleClick = async () => {
    await navigate("/");
    setIsBurgerOpened(false);
    scrollToButtom();
  };
  //-------------------
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  return (
    <ul
      {...props}
      className={`burger-items-list ${props.className ? props.className : ""}`}>
      <li onClick={() => {navigate("/executive"),setIsBurgerOpened(false)}}>
        {data?.navBar.executiveArchitecture}
      </li>
      <li onClick={() => {navigate("/partnerships"),setIsBurgerOpened(false)}}>
        {data?.navBar.partnerships}
      </li>
      <li onClick={() => {navigate("/featured"),setIsBurgerOpened(false)}}>{data?.navBar.featured}</li>
      <li onClick={() =>{ navigate("/contact"),setIsBurgerOpened(false)}}>{data?.navBar.contact}</li>
      <li onClick={handleClick}>{data?.navBar.about}</li>
    </ul>
  );
};

export default EsdCompBurgerItemsList;
