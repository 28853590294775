import React, { useContext } from "react";
import "../../_dist/EsdCompEaDescriptiveAside.css";
import EsdCompButtonExecutive from "../buttons/EsdCompButtonExecutive";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../utils/SwitchedData";

interface EaDescriptiveAsideProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: "board" | "corporate";
}

const EsdCompEaDescriptiveAside = ({ ...props }: EaDescriptiveAsideProps) => {
  //use navigate---------------
  const navigate = useNavigate();
  //----------------------------
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  // switch the content depending on variants
  const contentSwitch = (variant: string) => {
    switch (variant) {
      case "board": // case of board variant---------------------------------
        return (
          <>
            <header className="ea-descriptive-aside-header">
              <h6 className="aside-ea-small-title">
                {data?.board.descriptiveAside.h_6}
              </h6>
              <h1 className="aside-ea-title">
                {data?.board.descriptiveAside.h_1}
              </h1>
            </header>
            <main className="aside-ea-paragraphes">
              <p className="aside-ea-paragraphe">
                {data?.board.descriptiveAside.p_1}
              </p>
              <p className="aside-ea-paragraphe">
                {data?.board.descriptiveAside.p_2}
              </p>
            </main>
            <EsdCompButtonExecutive
              onClick={() => navigate("/corporate")}
              className="executive-button"
            >
              {data?.board.descriptiveAside.text_button}
            </EsdCompButtonExecutive>
          </>
        );
      case "corporate": //case of corporate variant---------------------------
        return (
          <>
            <header className="ea-descriptive-aside-header">
              <h6 className="aside-ea-small-title">
                {data?.corporate.descriptiveAside.h_6}
              </h6>
              <h1 className="aside-ea-title">
                {data?.corporate.descriptiveAside.h_1}
              </h1>
            </header>
            <main className="aside-ea-paragraphes"></main>
            <EsdCompButtonExecutive
              onClick={() => navigate("/board")}
              className="executive-button"
            >
              <span>{data?.board.descriptiveAside.h_6}</span>{" "}
              {data?.corporate.descriptiveAside.text_button}
            </EsdCompButtonExecutive>
          </>
        );
      default:
        break;
    }
  };
  //----------------------------
  return (
    <div
      {...props}
      className={`ea-descriptive-aside-container ${
        props.className ? props.className : ""
      }`}
    >
      {contentSwitch(props.variant)}
    </div>
  );
};

export default EsdCompEaDescriptiveAside;
