import React, { ReactElement } from "react";

interface ImageValidatorProps {
  image: ReactElement;
}

const ImageValidator: React.FC<ImageValidatorProps> = ({ image }) => {
  // Check if the image prop is a valid img element
  if (!React.isValidElement(image) || image.type !== "img") {
    throw new Error("Invalid props. Expected an <img> element.");
  }

  return <>{image}</>; // Render the image element
};

export default ImageValidator;
