import React, { useContext, useRef } from "react";
import "../../../_dist/EsdCompBaseComponent.css";
import EsdCompHeroCard from "../../../components/cards/EsdCompHeroCard";
import EsdCompOffersComponent from "../../../components/EsdCompOffersComponent";
import EsdCompDescriptiveCard from "../../../components/cards/EsdCompDescriptiveCard";
import EsdCompErathisExpertise from "../../../components/EsdCompErathisExpertise";
import EsdCompErathisCaroussel from "../../../components/caroussel/EsdCompErathisCaroussel";
import EsdCompErathisGoal from "../../../components/EsdCompErathisGoal";
import EsdCompErathisMission from "../../../components/EsdCompErathisMission";
import EsdCompErathisConnections from "../../../components/EsdCompErathisConnections";
import EsdCompAboutUs from "../../../components/EsdCompAboutUs";
import EsdCompErathisSimpleCards from "../../../components/EsdCompErathisSimpleCards";
import ExecuteScrollComponent from "../../../utils/ExecuteScrollComponent";
import { refBottomContext, refContext } from "../layouts/MainLayout";

const EsdCompBaseComponent = () => {
  // Create a reference using the useRef hook--------------------------------
  const myRef = useRef<HTMLElement>(null);
  const myBottomRef = useContext(refBottomContext);
  const myTopRef = useContext(refContext);

  //-----------------------------------------------------------------------------
  return (
    <>
      <section ref={myTopRef ||myRef} className="base-component-container">
        <EsdCompHeroCard />
        <EsdCompErathisSimpleCards />
        <EsdCompOffersComponent />
        <EsdCompDescriptiveCard />
        <EsdCompErathisExpertise />
        <EsdCompErathisCaroussel />
        <EsdCompErathisGoal />
        <EsdCompErathisMission />
        <EsdCompErathisConnections />
        <EsdCompAboutUs id="test" ref={myBottomRef} />
     
      </section>
      <ExecuteScrollComponent scrollRef={myRef} />
    </>
  );
};

export default EsdCompBaseComponent;
