import React,{ HTMLProps } from 'react'
import "../../_dist/EsdCompUnderlinedTitle.css";
import EsdCompDivider from '../divider/EsdCompDivider';
interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
   id?:string;
  }
const EsdCompUndelinedTitle = ({...props}:HeadingProps) => {
  return (
   <h1 {...props}
   className={
    props.className ? `underline-title ${props.className}` : "underline-title"
  }>
{props.children}
<EsdCompDivider line="horizontal" className="underline-title-divider"/>
   </h1>
  )
}

export default EsdCompUndelinedTitle