import React, { useContext, useState } from "react";
import EsdCompEaButton from "../buttons/EsdCompEaButton";
import "../../_dist/EsdCompEaButtonsSection.css";
import { EaButtonsStateType } from "../../data/Types";
import { DataContext } from "../../utils/SwitchedData";

// Define the props interface for EsdCompEaButtonsSection
interface EaButtonsSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: "point" | "desire" | "corporate"; // variant of the buttons section
  setDidSelectButton: React.Dispatch<React.SetStateAction<EaButtonsStateType>>; // setState of the buttons when clicking
  didSelectButton: EaButtonsStateType; // the state of the buttons
  screen: string;
  scrollToSection?: (index: number) => void; //the scroll function
}

const EsdCompEaButtonsSection = ({
  scrollToSection,
  didSelectButton,
  setDidSelectButton,
  ...props
}: EaButtonsSectionProps) => {
  //state to show and not show the SES and SPO buttons on corporate----------
  const [isShowen, setIsShowen] = useState<boolean>(
    didSelectButton === "Executive Territory" ||
      didSelectButton === "Strategy Execution and Supervision (SES)" ||
      didSelectButton === "Strategy Planning and Oversight (SPO)"
      ? true
      : false
  );
  //------------------------------------------------------------------------
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);

  // switch the desktop content depending on variants
  const contentDesktopSwitch = (variant: string) => {
    switch (variant) {
      case "point": // case of point variant--------------------
        return (
          <div className="primary-button-section">
            <EsdCompEaButton
              id={
                didSelectButton === "Executive Solitude"
                  ? "selected-button"
                  : ""
              }
              onClick={() => setDidSelectButton("Executive Solitude")}>
              {data?.board.painPoints.eaButton.text_1}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={didSelectButton === "Overwork" ? "selected-button" : ""}
              onClick={() => setDidSelectButton("Overwork")}>
              {data?.board.painPoints.eaButton.text_2}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Hit the board, Jack!"
                  ? "selected-button"
                  : ""
              }
              onClick={() => setDidSelectButton("Hit the board, Jack!")}>
              {data?.board.painPoints.eaButton.text_3}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Missing Sleep and opportunities"
                  ? "selected-button"
                  : ""
              }
              onClick={() =>
                setDidSelectButton("Missing Sleep and opportunities")
              }>
              {data?.board.painPoints.eaButton.text_4}
            </EsdCompEaButton>
          </div>
        );
      case "desire": // case of desire variant--------------------
        return (
          <div className="primary-button-section">
            <EsdCompEaButton
              id={
                didSelectButton === "Understanding Management & leadership"
                  ? "selected-button"
                  : ""
              }
              onClick={() =>
                setDidSelectButton("Understanding Management & leadership")
              }>
              {data?.board.help.eaButton.text_1}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Decision-Making and KPIs"
                  ? "selected-button"
                  : ""
              }
              onClick={() => setDidSelectButton("Decision-Making and KPIs")}>
              {data?.board.help.eaButton.text_2}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Building the A team"
                  ? "selected-button"
                  : ""
              }
              onClick={() => setDidSelectButton("Building the A team")}>
              {data?.board.help.eaButton.text_3}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Solution-oriented mindset"
                  ? "selected-button"
                  : ""
              }
              onClick={() => setDidSelectButton("Solution-oriented mindset")}>
              {data?.board.help.eaButton.text_4}
            </EsdCompEaButton>
          </div>
        );
      case "corporate": //case of corporate variant--------------
        return (
          <>
            <div className="primary-button-section">
              <EsdCompEaButton
                id={
                  didSelectButton === "Operational Territory"
                    ? "selected-button"
                    : ""
                }
                onClick={() => {
                  setDidSelectButton("Operational Territory");
                  setIsShowen(false);
                }}>
                {data?.corporate.eaButton.text_1}
              </EsdCompEaButton>
              <EsdCompEaButton
                id={
                  didSelectButton === "Managerial Territory"
                    ? "selected-button"
                    : ""
                }
                onClick={() => {
                  setDidSelectButton("Managerial Territory");
                  setIsShowen(false);
                }}>
                {data?.corporate.eaButton.text_2}
              </EsdCompEaButton>
              <EsdCompEaButton
                id={
                  didSelectButton === "Executive Territory" ||
                  didSelectButton ===
                    "Strategy Execution and Supervision (SES)" ||
                  didSelectButton === "Strategy Planning and Oversight (SPO)"
                    ? "selected-button"
                    : ""
                }
                onClick={() => {
                  setDidSelectButton("Executive Territory");
                  setIsShowen(true);
                }}>
                {data?.corporate.eaButton.text_3}
              </EsdCompEaButton>
            </div>
            {isShowen && (
              <div className="primary-button-section">
                <EsdCompEaButton
                  id={
                    didSelectButton ===
                    "Strategy Execution and Supervision (SES)"
                      ? "selected-button-secondary"
                      : ""
                  }
                  className="secondary-button-section"
                  onClick={() =>
                    setDidSelectButton(
                      "Strategy Execution and Supervision (SES)"
                    )
                  }>
                  {data?.corporate.eaButton.text_4}
                </EsdCompEaButton>
                <EsdCompEaButton
                  id={
                    didSelectButton === "Strategy Planning and Oversight (SPO)"
                      ? "selected-button-secondary"
                      : ""
                  }
                  className="secondary-button-section"
                  onClick={() =>
                    setDidSelectButton("Strategy Planning and Oversight (SPO)")
                  }>
                  {data?.corporate.eaButton.text_5}
                </EsdCompEaButton>
              </div>
            )}
          </>
        );
      default:
        break;
    }
  };
  // switch the mobile content depending on variants
  const contentMobileSwitch = (variant: string) => {
    switch (variant) {
      case "point": // case of point variant--------------------
        return (
          <div className="primary-button-section">
            <EsdCompEaButton
              id={
                didSelectButton === "Executive Solitude"
                  ? "selected-button"
                  : ""
              } onClick={() => scrollToSection?.(0)}>
              {data?.board.painPoints.eaButton.text_1}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={didSelectButton === "Overwork" ? "selected-button" : ""}onClick={() => scrollToSection?.(1)}>
              {data?.board.painPoints.eaButton.text_2}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Hit the board, Jack!"
                  ? "selected-button"
                  : ""
              } onClick={() => scrollToSection?.(2)}>
              {data?.board.painPoints.eaButton.text_3}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Missing Sleep and opportunities"
                  ? "selected-button"
                  : ""
              }onClick={() => scrollToSection?.(3)}>
              {data?.board.painPoints.eaButton.text_4}
            </EsdCompEaButton>
          </div>
        );
      case "desire": // case of desire variant--------------------
        return (
          <div className="primary-button-section">
            <EsdCompEaButton
              id={
                didSelectButton === "Understanding Management & leadership"
                  ? "selected-button"
                  : ""
              } onClick={() => scrollToSection?.(0)}>
              {data?.board.help.eaButton.text_1}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Decision-Making and KPIs"
                  ? "selected-button"
                  : ""
              } onClick={() => scrollToSection?.(1)}>
              {data?.board.help.eaButton.text_2}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Building the A team"
                  ? "selected-button"
                  : ""
              } onClick={() => scrollToSection?.(2)}>
              {data?.board.help.eaButton.text_3}
            </EsdCompEaButton>
            <EsdCompEaButton
              id={
                didSelectButton === "Solution-oriented mindset"
                  ? "selected-button"
                  : ""
              } onClick={() => scrollToSection?.(3)}>
              {data?.board.help.eaButton.text_4}
            </EsdCompEaButton>
          </div>
        );
      case "corporate": //case of corporate variant--------------
        return (
          <>
            <div className="primary-button-section">
              <EsdCompEaButton
                id={
                  didSelectButton === "Operational Territory"
                    ? "selected-button"
                    : ""
                }
                onClick={() => scrollToSection?.(0)}>
                {data?.corporate.eaButton.text_1}
              </EsdCompEaButton>
              <EsdCompEaButton
                id={
                  didSelectButton === "Managerial Territory"
                    ? "selected-button"
                    : ""
                }
                onClick={() => scrollToSection?.(1)}>
                {data?.corporate.eaButton.text_2}
              </EsdCompEaButton>
              <EsdCompEaButton onClick={() => scrollToSection?.(2)}
                id={
                  didSelectButton === "Executive Territory" ||
                  didSelectButton ===
                    "Strategy Execution and Supervision (SES)" ||
                  didSelectButton === "Strategy Planning and Oversight (SPO)"
                    ? "selected-button"
                    : ""
                }>
                {data?.corporate.eaButton.text_3}
              </EsdCompEaButton>
            </div>
            {isShowen && (
              <div className="primary-button-section">
                <EsdCompEaButton onClick={() => scrollToSection?.(3)}
                  id={
                    didSelectButton ===
                    "Strategy Execution and Supervision (SES)"
                      ? "selected-button"
                      : ""
                  }
                  className="secondary-button-section">
                  {data?.corporate.eaButton.text_4}
                </EsdCompEaButton>
                <EsdCompEaButton onClick={() => scrollToSection?.(4)}
                  id={
                    didSelectButton === "Strategy Planning and Oversight (SPO)"
                      ? "selected-button"
                      : ""
                  }
                  className="secondary-button-section">
                  {data?.corporate.eaButton.text_5}
                </EsdCompEaButton>
              </div>
            )}
          </>
        );
      default:
        break;
    }
  };
  return (
    <div
      {...props}
      className={`ea-buttons-section-container ${
        props.className ? props.className : ""
      }`}>
      {props.screen === "mobile"
        ? contentMobileSwitch(props.variant)
        : contentDesktopSwitch(props.variant)}
    </div>
  );
};

export default EsdCompEaButtonsSection;
