import React, { HTMLProps, useContext } from "react";
import "../_dist/EsdCompFooterCopyright.css";
import { DataContext } from "../utils/SwitchedData";
interface ParagraphProps extends HTMLProps<HTMLParagraphElement> {
  id?: string;
}
const EsdCompFooterCopyright = ({ ...props }: ParagraphProps) => {
  // Access to the values data that is passed as a value to the provider.
const {data } = useContext(DataContext);
  return (
    <p
      className={
        props.className ? `copy-right-footer ${props.className}` : "copy-right-footer"
      }
    >
      {data?.aboutUs.copyRight}
    </p>
  );
};

export default EsdCompFooterCopyright;
