import React, { useContext } from "react";
import"../../_dist/EsdCompHeroCard.css"
import EsdCompDivider from "../divider/EsdCompDivider";
import EsdCompHeroButton from "../buttons/EsdCompHeroButton";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../utils/SwitchedData";

// Define the props interface for EsdCompComposedCard

interface EsdCompHeroCardProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompHeroCard = ({ ...props }: EsdCompHeroCardProps) => {
   //use navigate---------------
   const navigate=useNavigate();
   //----------------------------
 // Access to the values setData  that is passed as a value to the provider.
 const {data } = useContext(DataContext);
  return (
    <section title="Photo by Dylan Gillis in Unsplash"
      {...props}
      className={`hero-card-container ${
        props.className ? props.className : ""
      }`}>
      <section
        className="overlay-container"
        title="Photo By Dylan Gillis on Unsplash">
        <header className="hero-overlay-header">
          <EsdCompDivider className="hero-divider" line={"horizontal"}/>
          <h1>{data?.heroCard.h_1}</h1>
          <EsdCompDivider className="hero-divider"  line={"horizontal"}/>
        </header>
        <h2>{data?.heroCard.h_2}</h2>
        {/* <h3>
        {data?.heroCard.h_3}
        </h3> */}
        <EsdCompHeroButton onClick={()=>navigate("/contact")} className="hero-button-conatct">{data?.heroCard.text_button}</EsdCompHeroButton>
      </section>
    </section>
  );
};

export default EsdCompHeroCard;
