import React from 'react'
import "../../_dist/EsdCompSimpleCard.css"
import ChildValidator from '../../utils/ChildValidator';
// Define the props interface for EsdCompSimpleCard
interface EsdSimpleCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  id?:string;
}
const EsdCompSimpleCard = ({...props}:EsdSimpleCardProps) => {
  return (
    <div {...props} className={`simple-card-container ${
        props.className ? props.className : ""
      }`}>
 <ChildValidator>{props.children}</ChildValidator>
    </div>
  )
}

export default EsdCompSimpleCard