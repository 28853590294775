import React, { useContext } from "react";
import EsdCompCircleIcon from "../icons/EsdCompCircleIcon";
import "../../_dist/EsdCompRedirectTabs.css";
import { EaTaBsStateType } from "../../data/Types";
import { DataContext } from "../../utils/SwitchedData";

// Define the props interface for EsdCompRedirectTabs
interface RedirectTabsProps extends React.HTMLAttributes<HTMLUListElement> {
  id?: string;
  setHasSelectedTab: React.Dispatch<React.SetStateAction<EaTaBsStateType>>;
  hasSelectedTab: EaTaBsStateType;
}

const EsdCompRedirectTabs = ({
  hasSelectedTab,
  setHasSelectedTab,
  ...props
}: RedirectTabsProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const {data} = useContext(DataContext);
  return (
    <ul
      {...props}
      className={`redirect-tabs-container ${
        props.className ? props.className : ""
      }`}>
      <li
        id={hasSelectedTab === "point" ? "active" : "inactive"}
        className="left-tab"
        onClick={() =>
           setHasSelectedTab("point")
        }>
        {" "}
        <EsdCompCircleIcon
          id={hasSelectedTab === "point" ? "hidden" : ""}
          className="direction-icon"
        />{" "}
        {data?.board.tabs.li_1}
      </li>
      <li
        id={hasSelectedTab === "desire" ? "active" : "inactive"}
        className="right-tab"
        onClick={() =>
        setHasSelectedTab("desire")
        }>
         {data?.board.tabs.li_2}
        <EsdCompCircleIcon
          id={hasSelectedTab === "desire" ? "hidden" : ""}
          className="direction-icon right-direct-icon"
        />{" "}
      </li>
    </ul>
  );
};

export default EsdCompRedirectTabs;
