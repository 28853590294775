import React, { useContext, useEffect, useState } from 'react'
import EsdCompLanguageButton from '../../../components/buttons/EsdCompLanguageButton';
import { DataContext } from '../../../utils/SwitchedData';
import EsdCompBurgerMenu from '../communs/EsdCompBurgerMenu';

const EsdCompBurgerMenuFeature = () => {
  // Access to the values setData  that is passed as a value to the provider.
    const {setDataState } = useContext(DataContext);

     //----------------State Language-------------------------------------------------
    const [language, setLanguage] = useState(localStorage.getItem('language') || "English");

    //---------UseEffect----------------------------------------------------------------
    useEffect(() => {
      localStorage.setItem('language', language);
    }, [language]);

     //@Desc-Used to switch language----------------------------------------------------
    const LanguageSwitch = (language: string) => {
      switch (language) {
        case "English":
          return (
            <EsdCompLanguageButton className="navigation-bar-language-btn"
              onClick={() => {
                setLanguage("French");
               if(setDataState) {setDataState("French")}
              }}>
              EN
              </EsdCompLanguageButton>
          );
        case "French":
          return (
            <EsdCompLanguageButton className="navigation-bar-language-btn"
              onClick={() => {
                setLanguage("English");
                if(setDataState) {setDataState("English")}
              }}>
            FR
            </EsdCompLanguageButton>
          );
        default:
          return (
            <EsdCompLanguageButton className="navigation-bar-language-btn"
        
              onClick={() => {
                setLanguage("French");
                if(setDataState) {setDataState("English")}
              }}>
          EN
          </EsdCompLanguageButton>
          );
      }
    };
    //---------------------------------------------------------------------------------
  return (
    <EsdCompBurgerMenu language={language} LanguageSwitch={LanguageSwitch}/>
  )
}

export default EsdCompBurgerMenuFeature