import React from "react";
import "../../_dist/EsdCompEaButton.css";
interface NewDocButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
}
const EsdCompEaButton = ({ ...props }: NewDocButtonProps) => {
  return (
    <button
      {...props}
      className={`ea-button-common ${props.className ? props.className : ""}`}>
      {props.children}
    </button>
  );
};
export default EsdCompEaButton;
