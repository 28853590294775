import React from "react";
import "../../_dist/EsdCompKwkLogoFigure.css";

interface EsdCompKwkLogoFigureProps
  extends React.HTMLAttributes<HTMLImageElement> {id?:string
  }

const EsdCompKwkLogoFigure = (props: EsdCompKwkLogoFigureProps) => {
  return (
    <figure
      {...props}
      className={
        props.className
          ? `kwk-logo-figure ${props.className}`
          : "kwk-logo-figure"
      }>
      <img src="../images/KwkLOGO.svg" alt="logo kwk" />
    </figure>
  );
};

export default EsdCompKwkLogoFigure;
