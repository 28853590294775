import React, { useContext } from "react";
import "../../_dist/EsdCompComposedCardWithButton.css";
import EsdCompHeroButton from "../buttons/EsdCompHeroButton";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../utils/SwitchedData";

// Define the props interface for EsdCompComposedCardWithButton

interface EsdCompComposedCardWithButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompComposedCardWithButton = ({
  ...props
}: EsdCompComposedCardWithButtonProps) => {
   //use navigate---------------
   const navigate=useNavigate();
   //----------------------------
      // Access to the values data  that is passed as a value to the provider.
      const {data} = useContext(DataContext);
  return (
    <div
      {...props}
      className={`composed-card-with-button-container ${
        props.className ? props.className : ""
      }`}>
      <div className="composed-card-with-button-overlay">
        <div className="composed-card-with-button-description">
          <h1>{data?.connecting.title}</h1>
          <p>
          {data?.connecting.p}
          </p>
        </div>
        <EsdCompHeroButton onClick={()=>navigate("/contact")} className="composed-card-with-button-btn" variant="secondary" >{data?.connecting.text_button}</EsdCompHeroButton>
      </div>
    </div>
  );
};

export default EsdCompComposedCardWithButton;
