import React from "react";
import "../../../_dist/EsdCompNavbar.css";
import EsdCompErathisLogo from "../../../components/logos/EsdCompErathisLogo";
import EsdCompDivider from "../../../components/divider/EsdCompDivider";
import EsdCompNavigationMenu from "../../../components/tabs/EsdCompNavigationMenu";
import { useNavigate } from "react-router-dom";
import { useScrollToTop } from "../../../utils/HandleScrollToTop";

// Define the props interface for EsdCompNavbar

interface NavbarProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  LanguageSwitch: (language: string) => React.ReactNode;
  language: string;
}

const EsdCompNavbar = ({ LanguageSwitch, language, ...props }: NavbarProps) => {
  //-----------------------------------------------------------
  //use navigate---------------------------------------------
  const navigate = useNavigate();
  //useScroll to top
  const scrollToTop = useScrollToTop();
  //---------------------------------------------------------
  // @definition: a function used on click of the logo to navigate to the home page and scroll to the top
  const handleClick = () => {
    navigate("/");
    scrollToTop();
  };
//-------------------
  return (
    <>
      <nav
        {...props}
        className={`navigation-bar ${props.className ? props.className : ""}`}>
        <EsdCompErathisLogo
          onClick={handleClick}
          className="erathis-logo-nav"
          variant="simple"
        />
        {LanguageSwitch(language)}
        <EsdCompNavigationMenu />
      </nav>
      <EsdCompDivider className="navigation-divider" line={"vertical"} />
    </>
  );
};

export default EsdCompNavbar;
