import React from "react";
import "../_dist/EsdCompPartnersCard.css";

interface PartnerCardProps extends React.HTMLAttributes<HTMLDivElement> {
  urlImage: string;
}
const EsdCompPartnersCard = ({ ...props }: PartnerCardProps) => {
  return (
    <div
      {...props}
      className={`partner-card-container ${
        props.className ? props.className : ""
      }`}
    >
      <figure className="partner-card-image">
        <img alt="Image" src={props.urlImage} />
      </figure>
      <p>{props.children}</p>
    </div>
  );
};

export default EsdCompPartnersCard;
