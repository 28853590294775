import React, { useContext } from "react";
import EsdCompPartnersCard from "./EsdCompPartnersCard";
import "../_dist/EsdCompPartnersComponent.css";
import { DataContext } from "../utils/SwitchedData";
interface PartnersProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}
const EsdCompPartnersComponent = ({ ...props }: PartnersProps) => {
   // Access to the values setData  that is passed as a value to the provider.
   const { data } = useContext(DataContext);
  return (
    <div
      {...props}
      className={`parteners-container ${
        props.className ? props.className : ""
      }`}
    >
      <h1>{data?.card.h_1}</h1>
      <div className="parteners-card-container">
        <EsdCompPartnersCard className="partners-card-container-comp" urlImage="images/imageAfricain.svg">
        {data?.card.p_1}
        </EsdCompPartnersCard>
        <EsdCompPartnersCard className="partners-card-container-comp" urlImage="images/image3.svg">
        {data?.card.p_2}
        </EsdCompPartnersCard>
        <EsdCompPartnersCard className="partners-card-container-comp" urlImage="images/image4.svg">
        {data?.card.p_3}
        </EsdCompPartnersCard>
        <EsdCompPartnersCard className="partners-card-container-comp" urlImage="images/image5.svg">
        {data?.card.p_4}
        </EsdCompPartnersCard>
      </div>
    </div>
  );
};

export default EsdCompPartnersComponent;
