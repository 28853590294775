import React from "react";
import "../../_dist/EsdCompkwkFeaturedButton.css"
interface NewDocButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    id?:string
}
const EsdCompkwkFeaturedButton = ({ ...props }: NewDocButtonProps) => {
  return (
    <button
      {...props}
      className={`kwk-featured-button ${props.className?props.className:""}`}
    >
      {props.children}
    </button>
  );
};

export default EsdCompkwkFeaturedButton;
