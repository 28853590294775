import React from "react";
import "../../_dist/EsdCompUplayeredContentComponent.css";
import ImageValidator from "../../utils/ImageValidator";

// Define the props interface for EsdCompUplayeredContentComponent

interface UplayeredContentProps extends React.HTMLAttributes<HTMLDivElement> {
  decoration: "underlined" | "non-underlined";// variant for the type of paragraphe 
  image: React.ReactElement; // the image props must be a react element with type "img"
}

const EsdCompUplayeredContentComponent = ({
  ...props
}: UplayeredContentProps) => {
  return (
      <section
        {...props}
        className={`uplayered-content-container ${
          props.className ? props.className : ""
        }`}>
        <figure className="uplayered-content-figure">
          <ImageValidator image={props.image} />
        </figure>
        <p
          className={`uplayered-content-info-container info-content-${props.decoration}`}>
         {props.children}
        </p>
      </section>
  
  );
};

export default EsdCompUplayeredContentComponent;
