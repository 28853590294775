import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import EsdCompContactUs from "../communs/EsdCompContactUs";

const EsdCompContactFeature = () => {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    company: "",
    subject: "",
    message: "",
  });

  //email js

  const [hasShowResult, setHasShowresult] = useState(false);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formElement = e.target as HTMLFormElement;
    emailjs
      .sendForm(
        `${process.env.REACT_APP_SERVICE_ID}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,formElement,
        `${process.env.REACT_APP_PUBLIC_KEY}`
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

      setHasShowresult(true);

    setTimeout(() => {
      setHasShowresult(false);
    }, 3000);
    formElement.reset();
    setContact({
      name: "",
      email: "",
      company: "",
      subject: "",
      message: "",
    });
  };
  return (
    <>
      <EsdCompContactUs sendEmail={sendEmail} contact={contact} setContact={setContact} hasShowResult={hasShowResult} />
    </>
  );
};

export default EsdCompContactFeature;
