import React from "react";
import "../../_dist/EsdCompHeroButton.css";
interface NewDocButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "transparent";
}
const EsdCompHeroButton = ({...props }: NewDocButtonProps) => {
  return (
    <button
      {...props}
      className={`hero-button-common hero-button-${props.variant ? props.variant : "primary"} ${
        props.className ? props.className : ""
      }`}
    >
      {props.children}
    </button>
  );
};

export default EsdCompHeroButton;
