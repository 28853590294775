import React, { useContext } from "react";
import EsdCompUndelinedTitle from "./titles/EsdCompUnderlinedTitle";
import EsdCompComposedCard from "./cards/EsdCompComposedCard";
import "../_dist/EsdCompErathisExpertise.css";
import { DataContext } from "../utils/SwitchedData";

interface ErathisExpertiseProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompErathisExpertise = ({ ...props }: ErathisExpertiseProps) => {
   // Access to the values data  that is passed as a value to the provider.
   const {data } = useContext(DataContext);
  return (
    <section
      {...props}
      className={`erathis-expertise-container ${
        props.className ? props.className : ""
      }`}>
      <EsdCompUndelinedTitle>{data?.expertise.h_1}</EsdCompUndelinedTitle>
      <main className="erathis-cards-expertise">
        
        <EsdCompComposedCard className="product-management-card" variant={"with-overlay"} copyright="Luca Bravo">
          <h1>{data?.expertise.title.title_2}</h1>
          <p>
          {data?.expertise.p_2}
          </p>
        </EsdCompComposedCard>
        <EsdCompComposedCard className="insight-architecture-card"
          variant={"with-overlay"}
          copyright="Michael Dziedzic">
          <h1>{data?.expertise.title.title_4}</h1>
          <p>
          {data?.expertise.p_4}
          </p>
        </EsdCompComposedCard>
        <EsdCompComposedCard className="com-con-card" variant={"with-overlay"} copyright="Patrick Fore">
          <h1>{data?.expertise.title.title_3}</h1>
          <p>
          {data?.expertise.p_3}
          </p>
        </EsdCompComposedCard>
       
        <EsdCompComposedCard className="digitalization-card" variant={"with-overlay"} copyright="NASA">
          <h1>{data?.expertise.title.title_1}</h1>
          <p>
          {data?.expertise.p_1}
          </p>
        </EsdCompComposedCard>
        <EsdCompComposedCard className="workforce-transormation-card" variant={"with-overlay"} copyright="AARN GIRI">
          <h1>{data?.expertise.title.title_5}</h1>
          <p>
          {data?.expertise.p_5}
          </p>
        </EsdCompComposedCard>

        <EsdCompComposedCard className="saas-card" variant={"with-overlay"} copyright="Sigmund">
          <h1>{data?.expertise.title.title_6}</h1>
          <p>
          {data?.expertise.p_6}
          </p>
        </EsdCompComposedCard>
      </main>
    </section>
  );
};

export default EsdCompErathisExpertise;
