import React, { useContext } from "react";
import "../../_dist/EsdCompLogoComponent.css";
import EsdCompKwkLogoFigure from "./EsdCompKwkLogoFigure";
import EsdCompDivider from "../divider/EsdCompDivider";
import { DataContext } from "../../utils/SwitchedData";
interface EsdCompLogoComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompLogoComponent = ({ ...props }: EsdCompLogoComponentProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const {data } = useContext(DataContext);
  return (
    <div
      {...props}
      className={
        props.className
          ? `kwk-logo-component ${props.className}`
          : "kwk-logo-component"
      }>
      <EsdCompKwkLogoFigure className="logo-kwk" />
      <div className="kwk-logo-description">
        <h2>{data?.featured.title_1}</h2>
        <EsdCompDivider className="kwk-logo-divider" line={"horizontal"} />
        <h3>{data?.featured.title_2}</h3>
      </div>
    </div>
  );
};

export default EsdCompLogoComponent;
