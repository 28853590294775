import React from "react";
import "../../_dist/EsdCompInfoCard.css";

interface InfoCardProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompInfoCard = ({ ...props }: InfoCardProps) => {
  return (
    <div
      {...props}
      className={`info-card-container ${
        props.className ? props.className : ""
      }`}>
      <p>{props.children}</p>
    </div>
  );
};

export default EsdCompInfoCard;
