import React, { forwardRef, useContext, useState } from "react";
import "../../_dist/EsdCompUpLayeredSection.css";
import { EaButtonsStateType, EaTaBsStateType } from "../../data/Types";
import EsdCompUplayeredContentComponent from "./EsdCompUplayeredContentComponent";
import EsdCompPanel from "./EsdCompPanel";
import EsdCompEaButtonsSection from "./EsdCompEaButtonsSection";
import { DataContext } from "../../utils/SwitchedData";

// Define the props interface for EsdCompUpLayeredSection
interface UpLayeredSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  setHasSelectedTab?: React.Dispatch<React.SetStateAction<EaTaBsStateType>>;
  hasSelectedTab?: EaTaBsStateType;
  screen?:"mobile"|"desktop";
  sectionsRef?: React.MutableRefObject<Array<HTMLDivElement | null>>;
  variant:
    | "Executive Solitude"
    | "Overwork"
    | "Hit the board, Jack!"
    | "Missing Sleep and opportunities"
    | "Understanding Management & leadership"
    | "Decision-Making and KPIs"
    | "Building the A team"
    | "Solution-oriented mindset"
    | "Operational Territory"
    | "Managerial Territory"
    | "Executive Territory"
    | "Strategy Execution and Supervision (SES)"
    | "Strategy Planning and Oversight (SPO)";
}

const EsdCompUpLayeredSection = forwardRef<HTMLElement, UpLayeredSectionProps>(({sectionsRef,hasSelectedTab,...props}, ref) => {
  
  //state for the EA buttons-----------------
  const [didSelectButton, setDidSelectButton] = useState<EaButtonsStateType>(
    props.variant
  );

// Access to the values data  that is passed as a value to the provider.
const { data } = useContext(DataContext);
//---------------------------------------------------------------------------------

const scrollToSection= (index: number) => {
  if (sectionsRef && sectionsRef.current) {
    const card = sectionsRef.current[index] as HTMLDivElement | null;
    if (card) {
      card.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
//---------------------------------------------------------------------------------
  // switch the content depending on variants
  const contentSwitch = (variant: string) => {
    switch (variant) {
      case "Executive Solitude": //case of Executive Solitude variant--------------
        return (
          <>
            <EsdCompEaButtonsSection  screen={props.screen?props.screen:"desktop"}scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={hasSelectedTab?hasSelectedTab:"point"}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration={"underlined"}
              image={
                <img
                  src="../images/alex-mccarthy-unsplash.jpg"
                  alt="Photo by Alex McCarthy in unsplash"
                />
              }>
              <span>{data?.board.painPoints.paragraph.span_1}</span>{data?.board.painPoints.paragraph.p_1}
            </EsdCompUplayeredContentComponent>
          </>
        );
      case "Overwork": //case of Overwork variant--------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"}scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={hasSelectedTab?hasSelectedTab:"point"}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration={"underlined"}
              image={
                <img
                  src="../images/luis-villasmil-unsplash.jpg"
                  alt="Photo by Luis Villasmil in unsplash"
                />
              }>
              <span>{data?.board.painPoints.paragraph.span_2}</span>{data?.board.painPoints.paragraph.p_2}
            </EsdCompUplayeredContentComponent>
          </>
        );
      case "Hit the board, Jack!": //case of Hit the board, Jack! variant--------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"}scrollToSection={scrollToSection}
              variant={hasSelectedTab?hasSelectedTab:"point"}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration={"underlined"}
              image={
                <img
                  src="../images/wil-stewart-unsplash.jpg"
                  alt="Photo by Wil Stewart in unsplash"
                />
              }>
              <span>{data?.board.painPoints.paragraph.span_3}</span>{data?.board.painPoints.paragraph.p_3}
            </EsdCompUplayeredContentComponent>
          </>
        );
      case "Missing Sleep and opportunities": //case of Missing Sleep and opportunities variant--------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"}scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={hasSelectedTab?hasSelectedTab:"point"}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration={"underlined"}
              image={
                <img
                  src="../images/bruce-mars-unsplash.jpg"
                  alt="Photo by bruce mars in unsplash"
                />
              }>
              <span>{data?.board.painPoints.paragraph.span_4}</span>{data?.board.painPoints.paragraph.p_4}
            </EsdCompUplayeredContentComponent>
          </>
        );
      //=====================================================================================================================//
      case "Understanding Management & leadership": // case of Understanding Management & leadership variant--------------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"}scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={hasSelectedTab?hasSelectedTab:"desire"}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration="non-underlined"
              image={
                <img
                  src="../images/jeshoots-unsplash.jpg"
                  alt="Photo by JESHOOTS.COM in unsplash"
                />
              }>
             {data?.board.help.p_1}
            </EsdCompUplayeredContentComponent>
          </>
        );
      case "Decision-Making and KPIs": //case of Decision-Making and KPIs variant--------------
        return (
          <>
            {" "}
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"}scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={hasSelectedTab?hasSelectedTab:"desire"}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration="non-underlined"
              image={
                <img
                  src="../images/florian-schmetz-unsplash.jpg"
                  alt="Photo by Florian Schmetz in unsplash"
                />
              }>
             {data?.board.help.p_2}
            </EsdCompUplayeredContentComponent>
          </>
        );
      case "Building the A team": //case of Building the A team variant--------------
        return (
          <>
            {" "}
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"}scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={hasSelectedTab?hasSelectedTab:"desire"}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration="non-underlined"
              image={
                <img
                  src="../images/josh-calabrese-unsplash.jpg"
                  alt="Photo by Josh Calabrese in unsplash"
                />
              }>
              {data?.board.help.p_3}
            </EsdCompUplayeredContentComponent>
          </>
        );
      case "Solution-oriented mindset": //case of Solution-oriented mindset variant--------------
        return (
          <>
            {" "}
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"} scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={hasSelectedTab?hasSelectedTab:"desire"}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration="non-underlined"
              image={
                <img
                  src="../images/olav-ahrens-rotne-unsplash.jpg"
                  alt="Photo by Olav Ahrens Røtne in unsplash"
                />
              }>
              {data?.board.help.p_4}
            </EsdCompUplayeredContentComponent>
          </>
        );
      //=====================================================================================================================//
      case "Operational Territory": //case of Operational Territory variant--------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={"corporate"} scrollToSection={scrollToSection}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration="non-underlined"
              image={
                <img
                  src="../images/nik-unsplash.jpg"
                  alt="Photo by Nik in unsplash"
                />
              }>
               {data?.corporate.p_1}
            </EsdCompUplayeredContentComponent>
          </>
        );
      case "Managerial Territory": //case of Managerial Territory variant--------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"} scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={"corporate"}           />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration="non-underlined"
              image={
                <img
                  src="../images/eden-constantino-unsplash.jpg"
                  alt="Photo by Eden Constantino in unsplash"
                />
              }>
              {data?.corporate.p_2}
            </EsdCompUplayeredContentComponent>
          </>
        );
      case "Executive Territory": //case of Executive Territory variant--------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"} scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={"corporate"}
            />
            <EsdCompUplayeredContentComponent className={didSelectButton.toLowerCase()}
              decoration="non-underlined"
              image={
                <img
                  src="../images/rodeo-unsplash.jpg"
                  alt="Photo by Rodeo Project Management Software in unsplash"
                />
              }>
             {data?.corporate.p_3}
            </EsdCompUplayeredContentComponent>
          </>
        );
      //=====================================================================================================================//
      case "Strategy Execution and Supervision (SES)": //case of Strategy Execution and Supervision (SES) variant--------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"} scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={"corporate"}
            />
            <EsdCompPanel variant={"ses"} />
          </>
        );
      case "Strategy Planning and Oversight (SPO)": //case of Strategy Planning and Oversight (SPO) variant--------------
        return (
          <>
            <EsdCompEaButtonsSection screen={props.screen?props.screen:"desktop"} scrollToSection={scrollToSection}
              setDidSelectButton={setDidSelectButton}
              didSelectButton={didSelectButton}
              variant={"corporate"}
            />
            <EsdCompPanel variant={"spo"} />
          </>
        );
      default:
        break;
    }
  };
  //-------------------------------------------------------------------------------------------------------------------------
  return (
    <section ref={ref}
      {...props}
      className={`up-layered-section-container ${
        props.className ? props.className : ""
      } `}>
      {contentSwitch(didSelectButton)}
    </section>
  );
});

EsdCompUpLayeredSection.displayName = "EsdCompUpLayeredSection";

export default EsdCompUpLayeredSection;
