import React, { useState, useEffect } from "react";
import "../../_dist/EsdCompCaroussel.css";
import EsdCompCarousselDot from "./EsdCompCarousselDot";
import EsdCompCarousselSlide from "./EsdCompCarousselSlide";

/* Props for the EsdCompCaroussel component.*/
interface EsdCompCarousselProps {
  children: React.ReactNode;
  autoplay?: boolean;
  interval?: number;
}
/* Carousel component with slide and dot functionality.*/
const EsdCompCaroussel: React.FC<EsdCompCarousselProps> = ({
  children,
  autoplay = false,
  interval = 10000,
}) => {
  // Current
  const [currentSlide, setCurrentSlide] = useState(0);

  /*-------------------------------------------
   *Handles the selection of a slide.
   *@param index - The index of the selected slide.
   ----------------------------------------------*/
  const handleChooseSlide = (index: number) => {
    if (index < 0) {
      setCurrentSlide(childrenCount - 1);
    } else if (index >= childrenCount) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(index);
    }
  };
  //---------------------------------------------------------

  // get the number of children slides-----------------------
  const childrenCount = React.Children.count(children);
  //----------------------------------------------------
  useEffect(() => {
    let timeoutId: number;
    if (autoplay) {
      timeoutId = window.setTimeout(() => {
        setCurrentSlide(
          currentSlide === childrenCount - 1 ? 0 : currentSlide + 1 //-- Move to the next slide, or wrap to the first slide if at the end.
        );
      }, interval);
    }
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId); //---Clear the timout----
      }
    };
  }, [autoplay, currentSlide, childrenCount, interval]);

  const carousselDots = Array.from({ length: childrenCount }).map((_, index) => (
    <EsdCompCarousselDot
      key={index}
      isActive={index === currentSlide}
      onClick={() => handleChooseSlide(index)}
    />
  ));
  return (
    <div className="carousel-container">
      <div className="slides-container">
        {React.Children.map(children, (child, index) => (
          <EsdCompCarousselSlide
            key={index}
            isActive={index === currentSlide}
            onClick={() => handleChooseSlide(index)}>
            {child}
          </EsdCompCarousselSlide>
        ))}
      </div>
      <div className="dots-container">{carousselDots}</div>
    </div>
  );
};

export default EsdCompCaroussel;
