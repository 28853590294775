import React, { useState } from "react";
import "../../../_dist/EsdCompBurgerMenu.css";
import EsdCompBurgerButton from "../../../components/buttons/EsdCompBurgerButton";
import EsdCompErathisLogo from "../../../components/logos/EsdCompErathisLogo";
import EsdCompBurgerItemsList from "../../../components/tabs/EsdCompBurgerItemsList";
import { useNavigate } from "react-router";
import { useScrollToTop } from "../../../utils/HandleScrollToTop";

// Define the props interface for EsdCompUplayeredContentComponent

interface BurgerMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  LanguageSwitch: (language: string) => React.ReactNode;
  language: string;
}

const EsdCompBurgerMenu = ({
  LanguageSwitch,
  language,
  ...props
}: BurgerMenuProps) => {
  //state for burger menu--------------------------------------
  const [isBurgerOpened, setIsBurgerOpened] = useState(false);
   //-----------------------------------------------------------
  //use navigate---------------------------------------------
  const navigate = useNavigate();
  //useScroll to top
  const scrollToTop = useScrollToTop();
  //---------------------------------------------------------
  // @definition: a function used on click of the logo to navigate to the home page and scroll to the top
  const handleClick = () => {
    navigate("/");
    setIsBurgerOpened(false)
    scrollToTop();
  };
//-------------------
  return (
    <>
      <nav
        {...props}
        className={`${
          isBurgerOpened
            ? "burger-menu-container-opened"
            : "burger-menu-container"
        } ${props.className ? props.className : ""}`}>
        <EsdCompBurgerButton
          isBurgerOpened={isBurgerOpened}
          setIsBurgerOpened={setIsBurgerOpened}
        />
        {isBurgerOpened && (
          <div className="burger-menu-content">
            <EsdCompErathisLogo
              onClick={handleClick}
              className="burger-logo"
              variant={"composed"}
            />
            <EsdCompBurgerItemsList setIsBurgerOpened={setIsBurgerOpened} />
          </div>
        )}
        {isBurgerOpened && LanguageSwitch(language)}
      </nav>
      {isBurgerOpened && (
        <div
          className="burger-wrapper"
          onClick={() => setIsBurgerOpened(false)}></div>
      )}
    </>
  );
};

export default EsdCompBurgerMenu;
