import React from "react";

interface ChildValidatorProps {
  children: React.ReactNode; //The children to be validated

}

export const ChildValidator: React.FC<ChildValidatorProps> = ({
  children,

}) => {
  const validatedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return null; // If the child is not a valid React element, return null
    }

    const { type } = child;

    if (type !== "h1" && type !== "p") {
      throw new Error("Children must be either <h1> or <p> elements"); // If the child type is not 'h1' or 'p', throw an error with the specified error text
      
    }

    return child; // Return the validated child
  });

  return <>{validatedChildren}</>; // Render the validated children
};

export default ChildValidator;
