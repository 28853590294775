import React, { useContext } from "react";
import"../_dist/EsdCompErathisConnections.css"
import EsdCompUndelinedTitle from "./titles/EsdCompUnderlinedTitle";
import EsdCompComposedCardWithButton from "./cards/EsdCompComposedCardWithButton";
import { DataContext } from "../utils/SwitchedData";

interface ErathisConnectionsProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompErathisConnections = ({ ...props }: ErathisConnectionsProps) => {
   // Access to the values data  that is passed as a value to the provider.
   const {data} = useContext(DataContext);
  return (
    <section
      {...props}
      className={`erathis-connections-container ${
        props.className ? props.className : ""
      }`}>
      <EsdCompUndelinedTitle>{data?.connecting.h_1}</EsdCompUndelinedTitle>
      <EsdCompComposedCardWithButton/>
    </section>
  );
};

export default EsdCompErathisConnections;
