import { useContext } from "react";
import { refBottomContext } from "../domains/erathisFP/layouts/MainLayout";


export function useScrollToBottom() {
  const myBottomRef = useContext(refBottomContext);

  const scrollToBottom = ()=> {
    if (myBottomRef && myBottomRef.current) {
      myBottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return scrollToBottom;
}
