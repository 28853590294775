import "../../_dist/EsdCompCarousselSlide.css";

interface EsdCompCarousselSlideProps {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
}
const EsdCompCarousselSlide: React.FC<EsdCompCarousselSlideProps> = ({
  ...props
}: EsdCompCarousselSlideProps) => {
 
  return (
    <div
      className={`slide-carousel ${props.isActive ? "active-slide" : ""}`}
      onClick={props.onClick}>
      {props.children}
    </div>
  );
};
export default EsdCompCarousselSlide;
