import React, { useContext, useRef } from "react";
import "../../../_dist/EsdCompkwkFeaturedComponent.css";
import EsdCompkwkFeaturedButton from "../../../components/buttons/EsdCompkwkFeaturedButton";
import EsdCompLogoComponent from "../../../components/logos/EsdCompLogoComponent";
import ExecuteScrollComponent from "../../../utils/ExecuteScrollComponent";
import { DataContext } from "../../../utils/SwitchedData";
interface KwkFeaturedProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}
const EsdCompkwkFeaturedComponent = ({ ...props }: KwkFeaturedProps) => {
   // Create a reference using the useRef hook--------------------------------
   const myRef = useRef<HTMLElement>(null);
   //-----------------------------------------------------------------------------
   // Access to the values data  that is passed as a value to the provider.
   const {data } = useContext(DataContext);
  return (<>
    <section ref={myRef}
      {...props}
      className={`kwk-featured-container ${
        props.className ? props.className : ""
      }`}
    >
      <div className="kwk-featured-first-part">
        <div className="kwk-featured-first-box">
        <h1>{data?.featured.h_1}</h1>
        <div className="kwk-featured-paragraph-container">
        <p>
        {data?.featured.p_1}
        </p>
        <p>
        {data?.featured.p_2}
        </p>
        </div>
        </div>
        <EsdCompLogoComponent className="comp-logo-featured"/>
      </div>
      <div className="kwk-featured-second-part">
      <ul className="kwk-featured-list-container">
          <li>{data?.featured.li_1}</li>
          <li>{data?.featured.li_2}</li>
          <li>{data?.featured.li_3}</li>
        </ul>
      <EsdCompkwkFeaturedButton className="kwk-featured-button">{data?.featured.text_button}</EsdCompkwkFeaturedButton>
      </div>
    </section>
    <ExecuteScrollComponent scrollRef={myRef}/>
    </>
  );
};

export default EsdCompkwkFeaturedComponent;
