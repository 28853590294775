import React, {SVGProps} from "react";
import "../../_dist/EsdCompLinkedinIcon.css";
interface NewDocIconProps extends SVGProps<SVGSVGElement> {id?:string
    //Custom all properties
  }
const EsdCompLinkedinIcon = ({ ...props }: NewDocIconProps) => {
  return (
    <svg
      {...props}
      className={
        props.className ? `icon-linkedin ${props.className}` : "icon-linkedin"
      }
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.4881 1.9248H4.51219C3.08585 1.9248 1.92871 3.08195 1.92871 4.50829V31.4842C1.92871 32.9105 3.08585 34.0677 4.51219 34.0677H31.4881C32.9144 34.0677 34.0716 32.9105 34.0716 31.4842V4.50829C34.0716 3.08195 32.9144 1.9248 31.4881 1.9248ZM31.4881 31.4962C13.4961 31.4922 4.50014 31.4882 4.50014 31.4842C4.50416 13.4922 4.50818 4.49623 4.51219 4.49623C22.5042 4.50025 31.5001 4.50427 31.5001 4.50829C31.4961 22.5003 31.4921 31.4962 31.4881 31.4962ZM6.69389 13.9744H11.4631V29.3145H6.69389V13.9744ZM9.0805 11.877C10.6033 11.877 11.8448 10.6395 11.8448 9.11275C11.8448 8.74974 11.7733 8.39028 11.6344 8.05491C11.4954 7.71953 11.2918 7.41479 11.0351 7.15811C10.7785 6.90142 10.4737 6.6978 10.1383 6.55888C9.80296 6.41997 9.44351 6.34847 9.0805 6.34847C8.71749 6.34847 8.35803 6.41997 8.02265 6.55888C7.68727 6.6978 7.38254 6.90142 7.12585 7.15811C6.86916 7.41479 6.66555 7.71953 6.52663 8.05491C6.38771 8.39028 6.31621 8.74974 6.31621 9.11275C6.31219 10.6395 7.54969 11.877 9.0805 11.877ZM19.2176 21.7248C19.2176 19.7239 19.5992 17.7873 22.0783 17.7873C24.5211 17.7873 24.5573 20.0735 24.5573 21.8534V29.3145H29.3225V20.9011C29.3225 16.7708 28.4305 13.5927 23.6051 13.5927C21.2867 13.5927 19.7318 14.8663 19.093 16.0717H19.0287V13.9744H14.4524V29.3145H19.2176V21.7248Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EsdCompLinkedinIcon;
