import React, { useRef } from "react";
import "../../../_dist/EsdCompEaContent.css";
import EsdCompEaDescriptiveAside from "../../../components/ExecutiveArchitecture/EsdCompEaDescriptiveAside";
import EsdCompEaDetailedBox from "../../../components/ExecutiveArchitecture/EsdCompEaDetailedBox";
import ExecuteScrollComponent from "../../../utils/ExecuteScrollComponent";

interface BoardOfManagementProps extends React.HTMLAttributes<HTMLDivElement> {
 variant: "board" |"corporate";
}

const EsdCompEaContent = ({ ...props }: BoardOfManagementProps) => {
   // Create a reference using the useRef hook--------------------------------
   const myRef = useRef<HTMLElement>(null);
   //--------------------------------------------------------------------------
  
  return (<>
    <section ref={myRef}
      {...props}
      className={`ea-content-container ${
        props.className ? props.className : ""
      }`}>
      <EsdCompEaDescriptiveAside variant={props.variant} />
    <EsdCompEaDetailedBox variant={props.variant}/>
    </section>
    <ExecuteScrollComponent scrollRef={myRef}/>
    </>
  );
};
export default EsdCompEaContent;
