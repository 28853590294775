import { useContext } from "react";
import { refContext } from "../domains/erathisFP/layouts/MainLayout";

export function useScrollToTop() {
  const myTopRef = useContext(refContext);

  const scrollToTop = () => {
    if (myTopRef && myTopRef.current) {
      myTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return scrollToTop;
}
