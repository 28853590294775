import React, { forwardRef, useContext } from "react";
import "../_dist/EsdCompAboutUs.css";
import EsdCompUndelinedTitle from "./titles/EsdCompUnderlinedTitle";
import EsdCompLinkedinIcon from "./icons/EsdCompLinkedinIcon";
import EsdCompInstagramIcon from "./icons/EsdCompInstagramIcon";
import EsdCompFooterCopyright from "./EsdCompFooterCopyright";
import { DataContext } from "../utils/SwitchedData";

// Define the props interface for EsdCompAboutUs
interface AboutUsProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: React.Ref<HTMLElement>;
}
const EsdCompAboutUs = forwardRef<HTMLElement, AboutUsProps>((props, ref) => {
  // Access to the values data that is passed as a value to the provider.
  const { data } = useContext(DataContext);

  return (
    <footer
      ref={ref}
      {...props}
      className={`esd-comp-about-us-container ${
        props.className ? props.className : ""
      }`}>
      <EsdCompUndelinedTitle>{data?.aboutUs.h_1}</EsdCompUndelinedTitle>
      <p>{data?.aboutUs.p}</p>
      <div className="location-follow-container">
        <div className="location-follow-box-container location-box-container">
          <h1>{data?.aboutUs.location}</h1>
          <p>{data?.aboutUs.p_1}</p>
          <p>{data?.aboutUs.p_2}</p>
        </div>
        <div className="location-follow-box-container follow-box-container">
          <h1>{data?.aboutUs.follow}</h1>
          <div className="about-us-comp-icons">
          <a href="https://www.linkedin.com/company/75004409/">
            <div className="about-us-icon-container">
              <EsdCompLinkedinIcon className="esd-comp-about-us-icon" />
              <label>{data?.aboutUs.linkedin}</label>
            </div>
            </a>
            <a href="https://www.instagram.com/erathis.global/">
            <div className="about-us-icon-container">
             
                <EsdCompInstagramIcon className="esd-comp-about-us-icon" />
                <label> {data?.aboutUs.instagram}</label>
              
            </div>
            </a>
            {/* <div className="about-us-icon-container">
              <EsdCompTwitterIcon className="esd-comp-about-us-icon" />
              <label>{data?.aboutUs.twitter}</label>
            </div>
            <div className="about-us-icon-container">
              <EsdCompFacebookIcon className="esd-comp-about-us-icon" />
              <label>{data?.aboutUs.facebook}</label>
            </div> */}
          </div>
        </div>
      </div>
      <EsdCompFooterCopyright />
    </footer>
  );
});
EsdCompAboutUs.displayName = "EsdCompAboutUs";

export default EsdCompAboutUs;
