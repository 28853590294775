import React from "react";
import"../../_dist/EsdCompConfirmIcon.css";

interface ConfirmIconProps extends React.SVGProps<SVGSVGElement> {
  id?: string;
}

const EsdCompConfirmIcon = ({ ...props }: ConfirmIconProps) => {
  return (
    <svg
      {...props}
      className={`icon-confirm ${props.className?props.className:""}` 
      }
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7001 6.92801H13.6467C13.4176 6.92801 13.1997 7.03807 13.065 7.22674L9.53411 12.1232L7.93489 9.90408C7.80013 9.71766 7.5845 9.60535 7.35316 9.60535H6.29974C6.15374 9.60535 6.06839 9.77156 6.15374 9.89061L8.95238 13.7719C9.01849 13.8641 9.10565 13.9393 9.20662 13.9912C9.30759 14.0431 9.41947 14.0701 9.53299 14.0701C9.64651 14.0701 9.75839 14.0431 9.85936 13.9912C9.96034 13.9393 10.0475 13.8641 10.1136 13.7719L14.8439 7.21326C14.9315 7.09422 14.8461 6.92801 14.7001 6.92801Z"
        fill="currentColor"
      />
      <path
        d="M10.5 0.4375C4.94316 0.4375 0.4375 4.94316 0.4375 10.5C0.4375 16.0568 4.94316 20.5625 10.5 20.5625C16.0568 20.5625 20.5625 16.0568 20.5625 10.5C20.5625 4.94316 16.0568 0.4375 10.5 0.4375ZM10.5 18.8555C5.88652 18.8555 2.14453 15.1135 2.14453 10.5C2.14453 5.88652 5.88652 2.14453 10.5 2.14453C15.1135 2.14453 18.8555 5.88652 18.8555 10.5C18.8555 15.1135 15.1135 18.8555 10.5 18.8555Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EsdCompConfirmIcon;
