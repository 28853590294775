import React from 'react'
import"../_dist/EsdCompMessageAlert.css"
import EsdCompConfirmIcon from './icons/EsdCompConfirmIcon';

interface MessageProps extends React.HTMLProps<HTMLParagraphElement> {
    id?: string;
  }

const EsdCompMessageAlert = ({...props}: MessageProps) => {
  return (
    <p {...props} className={`message-container ${props.className?props.className:""} `} >
        <EsdCompConfirmIcon/> {props.children}
    </p>
  )
}

export default EsdCompMessageAlert