import React from "react";
import "../../_dist/EsdCompButtonExecutive.css";
interface NewDocButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
}
const EsdCompButtonExecutive = ({ ...props }: NewDocButtonProps) => {
  return (
    <button
      {...props}
      className={`executive-button-comp ${
        props.className ? props.className : ""
      }`}
    >
      {props.children}
    </button>
  );
};

export default EsdCompButtonExecutive;
