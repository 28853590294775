export const dataFR = {
  /*******************************************************ERATHIS DATA************************************************************************/

  //Erathis NavBar----------------------------------------------------------------------------------------------------------------------------
  navBar: {
    executiveArchitecture: "Architecture Exécutive",
    partnerships: "Partenariats",
    featured: "En vedette",
    contact: " Contactez-nous",
    about: "À propos de nous",
  },

  //Erathis Hero Card----------------------------------------------------------------------------------------------------------------------
  heroCard: {
    h_1: "ERATHIS",
    h_2: "Le Sens de la Simplicité.",
    h_3: "",
    text_button: "Contactez-nous",
  },

  //Erathis simple card--------------------------------------------------------------------------------------------------------------------
  simpleCard: {
    title_1: "Humilité",
    title_2: "Efficacité",
    title_3: "Audace",
    p_1: {
      span_1: "",
      part_1:
        "Oubliez l'inutile. Nous sommes clairs et confiants de nos compétences. Pariez sur notre passion et notre expertise.",
      span_2: "",
      part_2: "",
    },
    p_2: {
      part_1:
        "Le savoir-faire, c'est poser des questions, identifier des problèmes pour concevoir des solutions  naturellement durables.",
      span_1: "",
    },
    p_3: {
      span_1:
        "Choisir les bons sentiers battus est tout aussi important que d’en ouvrir de nouveaux. Vos problèmes nous passionnent.",
      part_1: " ",
      span_2: "",
    },
  },

  //Erathis offers Section---------------------------------------------------------------------------------------------------------------------
  offers: {
    h_1: "Nos Services",
    title: {
      title_1: "Soutenir les dirigeants",
      title_2: "Former des forces exécutives",
      title_3: "la culture corporative",
      title_4: "Superviser les projets",
    },
    p_1: {
      part_1: "",
      span_1:
        "Gouvernance, Sécurité, Communication avec la Finance, Marque, Flux d'Influence, Architectures IEP (Intelligence, Expérience, Production).",
      part_2:
        "Aux côtés de vos dirigeants nous développons une agrégation saine de ces domaines afin de promouvoir une prise de décisions saine dans la création de stratégies à long et à court terme.",
    },
    p_2: {
      part_1:
        "Avec vos forces exécutives, nous construirons une nouvelle compréhension de ",
      span_1:
        "la communication transversale, le langage financier et la priorisation multipartite.",
      part_2:
        "Déterminer les bons types de postes de direction et comment les renforcer avec les meilleures équipes en tenant compte des compositions de compétences actuelles.",
    },
    p_3: {
      part_1:
        "Ensemble, nous intégrerons les connaissances que vous avez acquises jusqu'à présent dans la croissance d'une ",
      span_1: "cellule exécutive saine et synergique. ",
      part_2: "Nous avons conçu ",
      span_2: '"une Architecture des Flux d\'Influence"',
      part_3:
        "pour gérer le flux d'informations et la répartition des communications critiques dans votre organisation ainsi que la surveillance globale de vos expériences. ",
      span_3: "",
      part_4: "",
    },
    p_4: {
      part_1:
        "Après une discussion approfondie de vos objectifs, nous établirons les besoins et les limites de votre projet.  Ensemble, nous mettrons en œuvre des méthodes personnalisées pour établir un ",
      span_1: "journal de décisions stratégiques",
      part_2: ", et ",
      span_2: "une unitée de surveillance ",
      part_3: "pour ",
      span_3: "un pilotage ",
      part_4: "suffisamment flexible et adaptatif.",
    },
  },

  //Services Descriptive Card-----------------------------------------------------------------------------------------------------------------
  descriptiveCard: {
    h_1: "ERATHIS",
    p1: "Avec une mission à cœur et une vision en tête, une Organisation est une agrégation de connaissances et de compétences destinées à rendre service. C'est une idée fausse courante que l'informatique est en soi majeure et suffisante alors que sa véritable valeur réside dans le soutien d'une prise de décision exécutive synergique et intuitive.",
    p2:"Un bon choix n'est pas une question de compétence individuelle, les décideurs doivent être capables d'élargir leur compréhension de manière interdimensionnelle et de définir des priorités avec une logique de stabilité et de séquence afin de débloquer les avantages nécessaires pour dynamiser leurs stratégies individuelles. Les organisations qui réussissent comprennent la valeur du capital humain et sont capables de concevoir des systèmes qui englobent les protocoles les plus intuitifs pour une gouvernance solide, de bons renseignements et la production d'une meilleure expérience de services, toujours plus compatible aux clients ciblés",
    p3:"Avec Erathis, la coopération passe avant tout, car les solutions doivent sembler naturelles avec Le Sens de la Simplicité. C'est là que nous laissons nos compétences prendre le dessus, nous trouvons notre éclat dans l'agrégation des besoins, la médiation des sessions de stratégie et la composition des bonnes équipes pour l'exécution.",
  },

  //Erathis Expertise Section------------------------------------------------------------------------------------------------------------------------------
  expertise: {
    h_1: "nos expertises",
    title: {
      title_1: "Flux d'Exécution et Gouvernance",
      title_2: "Stratégie de Gestion des Produits",
      title_3: "Stratégies de Gestion de Marques",
      title_4: "Architecture des Flux d'Influence",
      title_5: "Transformation Strategique",
      title_6: "Produits SaaS",
    },
    p_1: "Avec une approche globale pour une meilleure transformation numérique et une culture d'entreprise avantageuse.",
    p_2: "Élaborez une stratégie de gestion des produits pour des collaborations internes fluides améliorant et une croissance plus stable.",
    p_3: "Nous fournissons à vos marques des stratégies de communication holistiques significatives avec des résultats optimisés et à fort impact.",
    p_4: "Diagnostic et approche holistique ADCC (accessible, durable, confortable, claire) pour vos architectures d'intelligence et d'expérience.",
    p_5: "Nous évaluons vos outils et systèmes actuels grâce à un conseil et une mise en œuvre personnalisés de bout en bout.",
    p_6: "Notre ensemble de produits numériques nous aide à fournir la meilleure expérience et les résultats les plus adéquats à nos clients.",
  },

  //Erathis team(Carousel)--------------------------------------------------------------------------------------------------------------------------
  team: {
    h_1: "Notre équipe",
    item_1: {
      h_2: "Insights & Oversight Officer - ALAEDDINE BAHRI",
      h_3: "Experience Designer, Graphic Designer, Photographer & Insight Officer",
      p_1: "«The wise ",
      span_1: "Innovator ",
      p_2: "does at once what the fool does finally.",
      span_2: " (N.M.)",
      p_4: "»",
    },
    item_2: {
      h_2: "Chief Insights Officer - Khalil BEL HAJ YAHIA",
      h_3: "UX Architect & Growth Specialist",
      p_1: " «To understand is to Transform what is ",
      span_1: "(J.K.)",
      span_2: "and to create is to defy what has yet to exist",
      p_2: ".»",
    },
    item_3: {
      h_2: "Chief Executive Officer - FADWA GMIDEN",
      h_3: "CEO & Software Engineer",
      p_1: "«Small projects need much more help than great ",
      span_1: "(D.A.)",
      span_2: "Therefore",
      p_2: ", If you can’t do great things, do small things in a great way. ",
      span_3: "(N.H.)",
      p_4: "»",
    },
    item_4: {
      h_2: "Chief Operations Officer - AYMEN CHATTI",
      h_3: "Branch Director & Operations Specialist",
      p_1: "«The desire for ",
      span_1: "Excellence",
      p_2: ", like the thirst for riches, increases ever with the acquisition of it. ",
      span_2: "(L.S.) ",
      p_3: "»",
    },
  },

  //Erathis Goals------------------------------------------------------------------------------------------------------------------------------
  goals: {
    h_1: "Nos Objectifs",
    title: {
      title_1: "D'ici 2027",
      title_2: "D'ici 2031",
      title_3: "D'ici 2037",
    },
    p_1: "Nous sommes reconnus pour avoir établi de nouvelles normes d'architecture exécutive pour tous les types d'organisations.",
    p_2: "Nous forgeons un écosystème professionnel et social international capable de guider les dirigeants face à l'imprévu et de prévenir les échecs décisionnels.",
    p_3: "Nous établissons des écosystèmes sur une échelle intercontinentale pour améliorer à l'international les collaborations mercantiles et la distribution d'expertise.",
  },

  //Erathis Mission-------------------------------------------------------------------------------------------------------------------------
  mission: {
    h_1: "Notre mission",
    title: "D'ici 2041",
    p: "Rassembler la science, les arts, la technologie, l'artisanat et tous les domaines du monde professionnel pour créer des projets à grande échelle qui bénéficieront et protégeront l'humanité.",
  },

  //Erathis Connecting----------------------------------------------------------------------------------------------------------------------
  connecting: {
    h_1: "Se connecter ensemble",
    title: "Pour faire partie de votre aventure",
    p: "Nous sommes diversifés, authentiques et sincèrement engagés à bâtir un monde meilleur.",
    text_button: "Contactez-nous",
  },

  //Erathis About Us-----------------------------------------------------------------------------------------------------------------------
  aboutUs: {
    h_1: "À propos",
    p: "Erathis accompagne les dirigeants dans la gestion et la délégation des différentes responsabilités qui leur incombent et les accompagne dans la composition et dans la formation d'une équipe de direction solide pour bâtir des organisations saines. Nous cherchons constamment à simplifier et à corriger les opérations quotidiennes tout en facilitant l'accès à des profils uniques. Notre approche est Neurodiverse, inclusive et totalement adaptative.",
    location: "Dans le monde",
    p_1: "Europe de l'Ouest, Belgique, Bruxelles",
    p_2: "Afrique du Nord, Tunisie, Gabès",
    follow: "Suivez-nous",
    linkedin: "Linkedin",
    instagram: "Instagram",
    twitter: "Twitter",
    facebook: "Facebook",
    copyRight: "Ⓒ ERATHIS CONSULTING - Tous droits réservés.",
  },

  //Erathis Contact Us--------------------------------------------------------------------------------------------------------------------
  contactUs: {
    h_2: "Avec Sens et Simplicité",
    name: "Nom",
    email: "Email",
    company: "Votre Entreprise",
    subject: "Objet",
    h_1: "Votre Message",
    message:
      "Laissez votre message ici et nous vous répondrons dans les plus brefs délais.",
    text_button: "Envoyer",
  },

  //Erathis Featured-------------------------------------------------------------------------------------------------------------------
  featured: {
    h_1: "KoreWaKore",
    p_1: "Nous fournissons une taverne en ligne basée sur les valeurs, les objectifs et la culture pour que les personnes et les organisations se rassemblent autour de projets significatifs et évaluent avec précision les indicateurs de performance clés des carrières et l'évolution des écosystèmes.",
    p_2: "Aperçu de l'industrie, planification de carrière individuelle ou en groupe, vitrines d'indépendants et d'organisations, portefeuilles dynamiques intelligents pour les personnes et les entités.",
    li_1: "Connect",
    li_2: "Collaborate",
    li_3: "Benchmark",
    title_1: "KoreWaKore",
    title_2: "Soutient vos résolutions",
    text_button: "À VENIR",
  },

  //Partnerships section erathis data-------------------------------------------------------------------------------------------------
  partnerships: {
    h_1: "ERATHIS",
    h_2: "Partenariats et plus...",
    part_1: {
      h_1: "Ce que nous voulons à faire",
      part_1: "Erathis accompagne ",
      span_1: "les organisations et les dirigeants ",
      part_2: "dans ",
      span_2: "l'adoption de méthodologies de travail durables et saines. ",
      part_3: "Notre objectif est de construire ",
      span_3: "un écosystème partenarial ",
      part_4:
        "fort et diversifié dans le cadre le l’unification d'un relais stable d’informations pertinentes et d'une architecture modulable. Pour y parvenir, nous souhaitons nous concentrer sur la dynamisation et la connexion de différents secteurs de travail par des moyens de communication intelligents. En recueillant des informations pertinentes, nous souhaitons promouvoir ",
      span_4:
        "une concurrence saine et une meilleure répartition des parts de marché pour nos partenaires.",
      part_5:
        "En fédérant autour d’un réseau stable et fort, les Partenariats pour le Développement Durable d’Erathis composent un écosystème ",
      span_5:
        "sentinelle des aléas écologiques, politiques, sociaux et économiques.",
    },
    part_2: {
      h_1: "Les bases du partenariat",
      p: "Nous détaillerons ici quelques-uns des principaux points de collaboration dont nous discuterons avec vous. Cette liste non exhaustive est un bon point de référence pour préparer la rencontre avec nos agents.",
    },
    generalities: {
      h_1: "Généralités",
      collaboration: {
        h_2: "À propos des “collaborations”",
        subject: {
          h_3: "Nous aborderons les sujets suivants",
          li_1: "Si nous partageons des visions et des objectifs similaires.",
          li_2: "De quelle manière êtes-vous prêt à changer et quel résultat souhaitez-vous atteindre ?",
          li_3: "Nous discuterons de votre culture d'entreprise et de votre éthique de travail.",
          li_4: "Nous évaluerons la clarté de votre marque et de votre communication globale.",
          li_5: 'Pack "Corporate Standards" obligatoire.',
        },
        entity: {
          h_3: "Sachez que notre société est contre le fait de traiter avec les entités suivantes",
          li_1: "Entités qui manquent de transparence juridique.",
          li_2: "Entités qui promeuvent la haine ou soutiennent des organisations qui divisent la société (Xénophobie, Antisémitisme, Terrorisme…).",
          li_3: "Entités présentant une opacité financière et des transactions illégales.",
          li_4: "Des entités qui font passer le profit avant la sécurité et l'intégrité des personnes.",
        },
      },
      goals: {
        h_2: "Objectifs de collaboration",
        h_3: "Nous aborderons les sujets suivants",
        li_1: "Coentreprises (projets dans lesquels nous collaborerons) répartition des risques/bénéfices.",
        li_2: "Tarifs des services partenaires.",
        li_3: "Utilisation d'actifs incorporels pour les relations publiques et les affaires, comme l’image marque, l'accès aux clients, etc.",
        li_4: "Accès aux opportunités par recommandation (projets clients, appels d'offres).",
      },
      media: {
        h_2: "Relations Publiques et Médias",
        consensus: {
          h_3: "Consensus sur le Web et les médias sociaux",
          paragraph:
            "Au cours de notre partenariat, nous discuterons d'un certain nombre de points concernant la publication et le partage d'informations sur les réseaux sociaux, ainsi que la manière dont nous nous adresserons mutuellement sur le Web.",
        },
        validation: {
          h_3: "Pas de publication sans validation",
          li_1: "Un agent de chaque côté du partenariat est désigné comme agent de liaison du partenariat.",
          li_2: "Les liaisons désignées du partenariat doivent à la fois valider les publications sur les réseaux sociaux et tout autre contenu Web avant de les publier.",
          li_3: "Un tableau des tâches partagées avec la planification de la gestion des médias sera utilisé à cet effet.",
          li_4: "Un canal de communication officiel sera ouvert entre les deux agents.",
          li_5: "Les agents peuvent valider les médias dans un groupe groupé.",
        },
        guideline: {
          h_3: "Conformément aux Directives Erathis",
          li_1: "Les liaisons désignées du partenariat supervisent la création d'articles spécifiques sur mesure pour le référencement.",
          li_2: "Les liaisons désignées du partenariat supervisent la création et le suivi des événements Hashtag.",
        },
        citation: {
          h_3: "Citation",
          li: "Erathis doit toujours être cité selon les directives fournies par la Division de la communication.",
        },
        subject: {
          h_3: "Nous aborderons également les sujets suivants",
          li_1: "Points de collaborations dans les médias et les relations publiques.",
          li_2: "Limites et lignes rouges mutuelles à propos de l’image de marque.",
          li_3: "Contextes de communication (Marge de flexibilité, protocoles, protection mutuelle, voies de contact, confidentialité).",
        },
      },
      project: {
        h_2: "Priorité du projet",
        subject: {
          h_3: "Nous aborderons les sujets suivants",
          li_1: "Priorité à voir les projets qui sont liés à votre domaine en tant que partenaire.",
          span_1: "",
          li_2: "",
          li_3: " Priorité à collaborer avec nous sur des projets sur lesquels nous avons juridiction.",
          span_2: "",
          li_4: "Ouverture aux formations partagées.",
          li_5: "Forfaits Erathis (Complet, partiel, formation,..).",
          li_6: "Dans le cas d'une formation, tous les « départements spécifiques » des partenaires sont ciblés pour suivre la même formation (Non obligatoire).",
          li_7: "Le service “ID Establishment” est obligatoire s’il y a planification annuelle et si la durée des packs choisis est de 3 mois ou plus.",
          li_8: "Définir les besoins et le niveau de priorité du projet.",
          li_9: "Sur le projet : complexité, budget, échéancier.",
          li_10:
            "Niveau de priorité du projet : comment il affecte notre marque et celle de nos partenaires.",
        },
        segmentation: {
          h_3: "Segmentation des partenaires",
          li_1: "Segmentation des partenaires : Type A, B, C et D.",
          li_2: "A - Accord simple.",
          li_3: "B - Accord collaboration partielle.",
          li_4: "C - Accord de collaboration complete.",
          li_5: " D - Service de “Conseil d'Administration” ajouté.",
          li_6: "Les besoins des partenaires en termes de projets et d'offres doivent être clairement spécifiés.",
          li_7: "Le niveau de priorité en termes de rang passe par la compatibilité du projet puis la segmentation puis l'ancienneté.",
        },
      },
    },
    advantages: {
      h_1: "Avantages",
      p: "Élargissez votre écosystème en découvrant notre écosystème de partenariat. Chez Erathis, nous nous soucions des relations à long terme qui se concentrent sur l'accès aux opportunités et aux marchés au-delà de la valeur monétaire à court terme.",
      Competitive: {
        h_2: "Tarifs de services compétitifs",
        h_3: "Nous aborderons les sujets suivants",
        li_1: "Une réduction de prix liée au choix du forfait selon ces critères:",
        li_2: "Type Pack",
        li_3: "Durée de la mission",
        li_4: "Prix ​​total",
        li_5: "Avantages de la collaboration avec nos autres partenaires",
      },
      Nonfunctional: {
        h_2: "Avantages non fonctionnels",
        h_3: "Nous aborderons les sujets suivants",
        li_1: "Accès à un espace partagé pour les marchés ou autres opportunités.",
        li_2: "Accès facilité à notre base de connaissance consolidée des Expertises et bonnes pratiques (KWK).",
        li_3: "Accès prioritaire aux formations, événements, etc.",
      },
      contact: {
        h_2: "Liberté de contact",
        h_3: "Nous aborderons les sujets suivants",
        li_1: "Spécifiez le nombre d'autorisations de contact unique pour les évaluations d'opportunité.",
        li_2: "Précisez un horaire de contact avantageux pour chaque forfait choisi.",
      },
      payment: {
        h_2: "Mode de paiement",
        p: "Nous aborderons les sujets suivants selon le type de partenariat.",
      },
      facilitation: {
        h_2: "Facilitation de paiement",
        p: "Nous discuterons des conditions de paiement de chaque forfait et service (montant/période) par type de partenariat.",
      },
    },
  },

  // Partners cards
  card: {
    h_1: "Nos partenaires",
    p_1: "African Diaspora Youth Forum in Europe",
    p_2: "DIGINOV - High End Development and Coding",
    p_3: "HIVE12 - Incubation and Coworking Spaces",
    p_4: "DESIGN CODE - Quality Interface Design",
  },

  //Erathis Executive architecture--------------------------------------------------------------------------------------------------------------
  executive: {
    span_1: "Service Exceptionnel -",
    p_1: "Conseil d'Administration",
    span_2: "Ce que nous faisons :",
    p_2: "Tout sur l’Architecture d’Organisations",
  },

  //Board of Management------------------------------------------------------------------------------------------------------------------------
  board: {
    descriptiveAside: {
      h_6: "Service Exceptionnel",
      h_1: "Conseil d'Administration",
      p_1: "Conseil d'Administration est un service d'assistance complet sur mesure pour les PDG et/ou les dirigeants qui sont submergés par des tâches opérationnelles et qui n'ont pas le temps de se consacrer à une vision stratégique. Nous vous guidons dans la création d'un plan de continuité et d'évolutivité de votre organisation.",
      p_2: "  Nous vous assisterons lors de réunions stratégiques régulières en matière de Target focus, Retrospectives et Pivotings. Le service consiste en un soutien continu pour optimiser le processus, identifier les bons profils pour alléger votre charge de direction et évaluer votre chaîne de valeur.",
      text_button: "Tout sur l’Architecture d’Organisations ",
    },
    tabs: {
      li_1: "VOS PAIN POINTS",
      li_2: "Vous aider à atteindre ce que vous désirez",
    },

    //YOUR PAIN POINTS----------------------------------------------------------------------------------------------------------------------
    painPoints: {
      eaButton: {
        text_1: "Solitude Exécutive",
        text_2: "Surmenage",
        text_3: "Hit the board, Jack!",
        text_4: "Manquer de sommeil et d'opportunités",
      },
      paragraph: {
        span_1: "Moi, moi-même et Je:",
        p_1: " Quand vous vous retrouvez à prendre toutes les décisions liées à la vitalité de l'entreprise et que vous n'avez personne autour de vous qui puisse vous aider à prendre les bonnes mesures.",
        span_2: "Déléguer... ou déléguer,",
        p_2: " ce n'est pas une question : Lorsque vous montez en gamme votre entreprise et que votre concentration se disperse sur de multiples tâches qui vous empêchent de canaliser votre énergie sur la stratégie. Pour rappel, la mise à l'échelle n'est pas seulement une question de plus d'opérations, la mise à l'échelle correcte consiste à déléguer des tâches exécutives.",
        span_3: "Au fond du trou: ",
        p_3: "Lorsque vous devenez touche-à-tout, vous couvrez tous les différents chapeaux requis pour le fonctionnement exécutif. Cela vous transforme en une position Joker Card pour éteindre tous les incendies sans nécessairement progresser dans vos plans.",
        span_4: " Dos au mur : ",
        p_4: "En raison du manque de temps et de concentration, ne pas avoir le soutien de la direction affecte les relations publiques et les partenaires et vous empêche de prendre le temps et la clarté d'esprit nécessaires pour évaluer les risques et opportunités potentiels.",
      },
    },

    //How we help you reach the outcomes you desire-------------------------------------------------------------------------------------
    help: {
      p: "Grâce au soutien et au mentorat, nous travaillerons ensemble à la construction d'une structure de direction solide qui réduira vos défis et offrira une excellente voie de croissance.",
      eaButton: {
        text_1: "Comprendre la gestion et le leadership",
        text_2: "Prise de décision et KPI",
        text_3: "Créer votre équipe A",
        text_4: "Un État d'esprit : Solutions!",
      },
      p_1: "Définissez comment vous dirigez la continuité de vos activités et toute valeur ajoutée grâce à la Stratégie. Évitez de vous mêler inutilement de la gestion tout en vous assurant que votre culture est de premier ordre.",
      p_2: "Créez un cadre personnalisé et efficace pour surveiller la croissance de votre organisation, y compris un flux soigneusement conçu pour les informations pertinentes fournissant tout le nécessaire pour une prise de décision optimale.",
      p_3: "Identifiez les responsabilités et les tâches qui peuvent être déléguées, trouvez la bonne solution et construisez un processus optimisé qui assure efficacité et valeur ajoutée.",
      p_4: "Concentrez-vous sur la croissance et sur vos points d’amélioration avec un état d'esprit à long terme qui évite les solutions tampon.",
    },
  },

  //Corporate Architecture Explained------------------------------------------------------------------------------------------------------
  corporate: {
    descriptiveAside: {
      h_6: "Ce que nous faisons :",
      h_1: "Tout sur l’Architecture d’Organisations ",
      span_1: "Service Exceptionnel",
      text_button: " - Conseil d'Administration",
    },
    h_1: "Toute organisation est une Matriochka de systèmes.",
    p: "Il est essentiel de comprendre que chaque système au sein d'une organisation représente des territoires avec des ensembles particuliers de responsabilités, de processus, de délivrables et de limites. Ils doivent communiquer en douceur pour cohabiter sereinement et en synergie.",
    eaButton: {
      text_1: "Territoire Opérationnel",
      text_2: "Territoire Managérial",
      text_3: "Territoire Exécutif",
      text_4: "Exécution et Supervision de la Stratégie (ESS)",
      text_5: "Planification et Supervision de la Stratégie (PSS)",
    },
    p_1: "L'opérationnel se concentre sur la mise en œuvre réussie d'une valeur ajoutée. Il englobe toute personne dont le travail consiste à produire un résultat concret dans lequel elle peut faire preuve de créativité et d'ingéniosité technique dans le cadre de ses tâches.",
    p_2: "Ce n'est pas parce que vous avez un résultat concret que vous avez de la valeur délivrée. Les excellents managers passent maîtres dans l'art de diviser efficacement les plans d'action et de coordonner les ressources et les tâches pour tirer le meilleur parti de leurs équipes.",
    p_3: "Les dirigeants se concentrent sur la création et la capture de la valeur par le biais de la stratégie et de l'accès au marché. Le système Executif en lui-même contient deux domaines distincts mais complémentaires.",
    p_4: "Comprend les frontières entre les différents fiefs ESS  et l'évaluation de l'impact/risque des décisions.",
    p_5: "Décompose la stratégie en un plan d'action viable et une distribution adéquate à la direction.",
    p_6: "Agit comme principal point de contact et de négociation pour les partenaires et les fournisseurs.",
    p_7: "PSS oriente la stratégie de l'organisation vers son écosystème externe et ses marchés potentiels.",
    p_8: "Crée une stratégie en transformant les objectifs de la vision en objectifs quantifiables.",
    p_9: "Crée les bons points de contrôle Alert & Insight (A&I).",
    p_10: "Facilite, Médie et est Décisionnaire final.e lors des conflits ESS.",
    p_11: "Supervise et valide la stratégie en mouvement.",
    p_12: "Agit en dehors des limites de l'organisation: Rainmaking, Prospecting et Closing.",
  },

  //message alert Architecture Explained------------------------------------------------------------------------------------------------------------------
  messageAlert: {
    paragraph: "Votre message a été envoyé avec succès.",
  },
};
