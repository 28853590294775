import React from "react";
import EsdCompDivider from "../divider/EsdCompDivider";
import "../../_dist/EsdCompUnderlinedInput.css";
interface InputProps extends React.AllHTMLAttributes<HTMLInputElement> {
  id?: string;
}
const EsdCompUnderlinedInput = ({ ...props }: InputProps) => {
  return (
    <div className="underline-input-container">
      <input
        {...props}
        className={
          props.className
            ? `underlined-input ${props.className}`
            : "underlined-input"
        }
        type={props.type}
        placeholder={props.placeholder}
      />
      <EsdCompDivider line="horizontal" className="underlined-input-divider" />
    </div>
  );
};

export default EsdCompUnderlinedInput;
