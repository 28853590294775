import React, { useContext } from "react";
import EsdCompCaroussel from "./EsdCompCaroussel";
import "../../_dist/EsdCompErathisCaroussel.css";
import EsdCompCarouselItem from "./EsdCompCarousselItem";
import EsdCompUndelinedTitle from "../titles/EsdCompUnderlinedTitle";
import { DataContext } from "../../utils/SwitchedData";

interface EsdCompErathisCarousselProps
  extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompErathisCaroussel = ({
  ...props
}: EsdCompErathisCarousselProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const {data } = useContext(DataContext);
  return (
    <section className={`erathis-caroussel-container ${props.className?props.className:""} `}{...props}>
      <EsdCompUndelinedTitle>{data?.team.h_1}</EsdCompUndelinedTitle>
      <EsdCompCaroussel autoplay={true}>
        <EsdCompCarouselItem variant="ala-bahri" />
        <EsdCompCarouselItem variant="khalil-bhy" />
        <EsdCompCarouselItem variant="fadwa-gmiden" />
        <EsdCompCarouselItem variant="aymen-chatti" />
      </EsdCompCaroussel>
    </section>
  );
};

export default EsdCompErathisCaroussel;
