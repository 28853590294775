import React from "react";
import "../../_dist/EsdCompTextarea.css";
interface TextareaDocProps
  extends React.AllHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
}

const EsdCompTextarea = React.forwardRef<HTMLTextAreaElement, TextareaDocProps>(
  (props, ref) => {
    return (
      <textarea
        {...props}
        ref={ref}
        className={`textarea-input ${props.className ? props.className : ""}`}
      >
        {props.children}
      </textarea>
    );
  }
);
EsdCompTextarea.displayName = "EsdCompTextarea";
export default EsdCompTextarea;
