import React from "react";
import "../../_dist/EsdCompLayeredButton.css";

interface EsdCompUplayeredButtontProps
extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "board" | "corporate";
}

const EsdCompLayeredButton = ({ ...props }: EsdCompUplayeredButtontProps) => {
  return (
    <button
    {...props}
      className={`uplayered-button ${props.variant} ${
        props.className ? props.className : ""
      }`} >
      {props.children}
    </button>
  );
};

export default EsdCompLayeredButton;
