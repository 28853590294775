import React, { useContext } from "react";
import "../_dist/EsdCompOffersComponent.css";
import EsdCompUndelinedTitle from "./titles/EsdCompUnderlinedTitle";
import EsdCompCardSideIcon from "./cards/EsdCompCardSideIcon";
import EsdCompLeadersIcon from "./icons/EsdCompLeadersIcon";
import EsdCompTrainingIcon from "./icons/EsdCompTrainingIcon";
import EsdCompCorporateIcon from "./icons/EsdCompCorporateIcon";
import EsdCompProjectsIcon from "./icons/EsdCompProjectsIcon";
import { DataContext } from "../utils/SwitchedData";

interface EsdCompOffersComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompOffersComponent = ({ ...props }: EsdCompOffersComponentProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  return (
    <section
      {...props}
      className={`offers-component-container ${
        props.className ? props.className : ""
      }`}
    >
      <EsdCompUndelinedTitle>{data?.offers.h_1}</EsdCompUndelinedTitle>
      <EsdCompCardSideIcon
        direction="left"
        variant="leader"
        icon={<EsdCompLeadersIcon />}
      >
        <h1>{data?.offers.title.title_1}</h1>
        <p>
          {data?.offers.p_1.part_1}
          <span>{data?.offers.p_1.span_1}</span> {data?.offers.p_1.part_2}
        </p>
      </EsdCompCardSideIcon>
      <EsdCompCardSideIcon
        direction="right"
        variant="training"
        icon={<EsdCompTrainingIcon />}
      >
        <h1>{data?.offers.title.title_2}</h1>
        <p>
          {data?.offers.p_2.part_1} <span>{data?.offers.p_2.span_1}</span>
          {data?.offers.p_2.part_2}
        </p>
      </EsdCompCardSideIcon>
      <EsdCompCardSideIcon
        direction="left"
        variant="corporate"
        icon={<EsdCompCorporateIcon />}
      >
        <h1>{data?.offers.title.title_3}</h1>
        <p>
          {data?.offers.p_3.part_1}
          <span>{data?.offers.p_3.span_1}</span>
          {data?.offers.p_3.part_2}
          <span>{data?.offers.p_3.span_2}</span> {data?.offers.p_3.part_3}
          <span>{data?.offers.p_3.span_3}</span> {data?.offers.p_3.part_4}
        </p>
      </EsdCompCardSideIcon>
      <EsdCompCardSideIcon
        direction="right"
        variant="project"
        icon={<EsdCompProjectsIcon />}
      >
        <h1>{data?.offers.title.title_4}</h1>
        <p>
          {data?.offers.p_4.part_1}
          <span>{data?.offers.p_4.span_1}</span>
          {data?.offers.p_4.part_2}
          <span>{data?.offers.p_4.span_2}</span> {data?.offers.p_4.part_3}{" "}
          <span>{data?.offers.p_4.span_3}</span>
          {data?.offers.p_4.part_4}
        </p>
      </EsdCompCardSideIcon>
    </section>
  );
};

export default EsdCompOffersComponent;
