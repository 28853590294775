import React, { useContext } from "react";
import "../_dist/EsdCompErathisSimpleCards.css";
import EsdCompSimpleCard from "./cards/EsdCompSimpleCard";
import { DataContext } from "../utils/SwitchedData";

interface ErathisSimpleCardsProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}
const EsdCompErathisSimpleCards = ({ ...props }: ErathisSimpleCardsProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const {data } = useContext(DataContext);
  return (
    <section
      {...props}
      className={`erathis-simple-cards-container ${
        props.className ? props.className : ""
      }`}>
      <EsdCompSimpleCard>
        <h1>{data?.simpleCard.title_1}</h1>
        <p>
          {" "}
          <span>{data?.simpleCard.p_1.span_1}</span>{data?.simpleCard.p_1.part_1}<span>{data?.simpleCard.p_1.span_2}</span>{data?.simpleCard.p_1.part_2}
        </p>
      </EsdCompSimpleCard>
      <EsdCompSimpleCard>
        <h1>{data?.simpleCard.title_2}</h1>
        <p>
        {data?.simpleCard.p_2.part_1}<span>{data?.simpleCard.p_2.span_1}</span>
        </p>
      </EsdCompSimpleCard>
      <EsdCompSimpleCard>
        <h1>{data?.simpleCard.title_3}</h1>
        <p><span> {data?.simpleCard.p_3.span_1}</span>
        {data?.simpleCard.p_3.part_1}<span>{data?.simpleCard.p_3.span_2}</span>
        </p>
      </EsdCompSimpleCard>
    </section>
  );
};

export default EsdCompErathisSimpleCards;
