import React, { useContext } from "react";
import "../../_dist/EsdCompNavigationMenu.css";
import EsdCompDivider from "../divider/EsdCompDivider";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../utils/SwitchedData";
import { useScrollToBottom } from "../../utils/HandleScrollToBottom";

// Define the props interface for EsdCompNavigationMenu
interface EsdCompNavigationTabsProps
  extends React.HTMLAttributes<HTMLUListElement> {
  id?: string;
}

const EsdCompNavigationMenu = ({ ...props }: EsdCompNavigationTabsProps) => {
  //Used for navigate-------------
  const navigate = useNavigate();
  //------------------------------
  //useScroll to botton
  const scrollToButtom = useScrollToBottom();
  //---------------------------------------------------------
  /* 
  @definition: a function used on click of the logo to navigate
   to the home page and scroll to the bottom
 @async function: the use of async is for navigate first to the home
  than scroll to the bottom
   */
  const handleClick = async () => {
    await navigate("/");
    scrollToButtom();
  };
  //-------------------

  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  //---------------------------------------------------------------------
  return (
    <>
      <ul
        {...props}
        className={`navigation-menu-list ${
          props.className ? props.className : ""
        }`}>
        <EsdCompDivider className="divider-menu" line="horizontal" />
        <li onClick={() => navigate("/executive")}>
          {data?.navBar.executiveArchitecture}
        </li>
        <EsdCompDivider className="divider-menu" line="horizontal" />
        <li onClick={() => navigate("/partnerships")}>
          {data?.navBar.partnerships}
        </li>
        <EsdCompDivider className="divider-menu" line="horizontal" />
        <li onClick={() => navigate("/featured")}>{data?.navBar.featured}</li>
        <EsdCompDivider className="divider-menu" line="horizontal" />
        <li onClick={() => navigate("/contact")}> {data?.navBar.contact}</li>
        <EsdCompDivider className="divider-menu" line="horizontal" />
        <li onClick={handleClick}>{data?.navBar.about}</li>
      </ul>
    </>
  );
};

export default EsdCompNavigationMenu;
