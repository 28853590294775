import React, { createContext, useRef } from "react";
import "../../../_dist/MainLayout.css";
import { Outlet, useLocation } from "react-router-dom";
import { SwitchedData } from "../../../utils/SwitchedData";
import EsdCompNavbarFeature from "../pages/EsdCompNavbarFeature";
import EsdCompBurgerMenuFeature from "../pages/EsdCompBurgerMenuFeature";

// Create the refContext using createContext for the to scroll to top
export const refContext = createContext<React.RefObject<HTMLElement> | null>(
  null
);
// Create the refContext using createContext for the to scroll to bottom

export const refBottomContext =
  createContext<React.RefObject<HTMLElement> | null>(null);
//------------------------------------------------------------------------
const MainLayout = () => {
  const myTopRef = useRef<HTMLElement>(null); // Define the ref for the top scroll
  const myBottomRef = useRef<HTMLElement>(null); // Define the ref for the bottom scroll
  const location = useLocation() // used to get the path ;

  return (
    <refBottomContext.Provider value={myBottomRef}>
      <refContext.Provider value={myTopRef}>
        <SwitchedData>
          <section className="layout-fp-erathis">
            <aside className="layout-navbar">
              <EsdCompNavbarFeature />
            </aside>
            <aside className="layout-burger">
              <EsdCompBurgerMenuFeature />
            </aside>
            <main id={`${location.pathname==="/contact"&&"layout-no-scroll"}`} className="layout-main">
              <Outlet />
            </main>
          </section>
        </SwitchedData>
      </refContext.Provider>
    </refBottomContext.Provider>
  );
};

export default MainLayout;
