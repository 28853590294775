import React, { useContext } from "react";
import EsdCompComposedCard from "./cards/EsdCompComposedCard";
import EsdCompUndelinedTitle from "./titles/EsdCompUnderlinedTitle";
import "../_dist/EsdCompErathisGoal.css";
import { DataContext } from "../utils/SwitchedData";

interface ErathisGoalsProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompErathisGoal = ({ ...props }: ErathisGoalsProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const {data} = useContext(DataContext);
  return (
    <section
      {...props}
      className={`erathis-goal-container ${
        props.className ? props.className : ""
      }`}>
      <EsdCompUndelinedTitle>{data?.goals.h_1}</EsdCompUndelinedTitle>
      <main className="erathis-cards-goal">
        <EsdCompComposedCard
          className="by-2027-card"
          variant={"with-overlay"}
          copyright="Kelly Sikkema">
          <h1>{data?.goals.title.title_1}</h1>
          <p>
          {data?.goals.p_1}
          </p>
        </EsdCompComposedCard>
        <EsdCompComposedCard
          className="by-2031-card"
          variant={"with-overlay"}
          copyright="Metin Ozer">
          <h1>{data?.goals.title.title_2}</h1>
          <p>
          {data?.goals.p_2}
          </p>
        </EsdCompComposedCard>
        <EsdCompComposedCard
          className="by-2037-card"
          variant={"with-overlay"}
          copyright="Nicole Geri">
          <h1>{data?.goals.title.title_3}</h1>
          <p>
          {data?.goals.p_3}
          </p>
        </EsdCompComposedCard>
      </main>
    </section>
  );
};

export default EsdCompErathisGoal;
