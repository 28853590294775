import { Route, Routes } from "react-router-dom";
import "./_dist/app.css";
import MainLayout from "./domains/erathisFP/layouts/MainLayout";
import EsdCompBaseComponent from "./domains/erathisFP/pages/EsdCompBaseComponent";
import EsdCompContactFeature from "./domains/erathisFP/pages/EsdCompContactFeature";
import EsdCompkwkFeaturedComponent from "./domains/erathisFP/pages/EsdCompkwkFeaturedComponent";
import EsdCompEaComponent from "./domains/erathisFP/pages/EsdCompEaComponent";
import EsdCompEaContent from "./domains/erathisFP/pages/EsdCompEaContent";
import EsdCompPartnershipComponent from "./domains/erathisFP/pages/EsdCompPartnershipComponent";



function App() {
  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<EsdCompBaseComponent />} />
          <Route path="/contact" element={<EsdCompContactFeature />} />
          <Route path="/featured" element={<EsdCompkwkFeaturedComponent />} />
          <Route
            path="/partnerships"
            element={<EsdCompPartnershipComponent />}
          />
          <Route path="/executive" element={<EsdCompEaComponent />} />
          <Route
            path="/board"
            element={<EsdCompEaContent variant={"board"} />}></Route>
          <Route
            path="/corporate"
            element={<EsdCompEaContent variant={"corporate"} />}></Route>
      
        </Route>
      </Routes>
    </div>
  );
}

export default App;
