import React, { SVGProps } from "react";
import "../../_dist/EsdCompCircleIcon.css";

interface NewDocIconProps extends SVGProps<SVGSVGElement> {id?:string}

const EsdCompCircleIcon = ({ ...props }: NewDocIconProps) => {
  return (
    <svg
      {...props}
      onClick={props.onClick}
      id={props.id}
      className={
        props.className ? `icon-cercle ${props.className}` : "icon-cercle"
      }
      width="1em"
      height="1em"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.02564 11.6465L13.551 15.6445C13.5779 15.664 13.6096 15.6757 13.6427 15.6782C13.6758 15.6808 13.7089 15.6742 13.7385 15.6591C13.768 15.6439 13.7928 15.6209 13.81 15.5926C13.8273 15.5643 13.8364 15.5317 13.8363 15.4985L13.8363 14.4451C13.8363 14.216 13.7262 13.9981 13.5398 13.8634L10.274 11.5005L13.5398 9.13757C13.7262 9.00281 13.8363 8.78718 13.8363 8.55583L13.8363 7.50241C13.8363 7.35642 13.6701 7.27107 13.551 7.35642L8.02564 11.3545C7.92681 11.4263 7.92681 11.5746 8.02564 11.6465Z"
        fill="currentColor"
      />
      <path
        d="M11.5 21.5625C17.0568 21.5625 21.5625 17.0568 21.5625 11.5C21.5625 5.94316 17.0568 1.4375 11.5 1.4375C5.94317 1.4375 1.4375 5.94316 1.4375 11.5C1.4375 17.0568 5.94316 21.5625 11.5 21.5625ZM11.5 3.14453C16.1135 3.14453 19.8555 6.88652 19.8555 11.5C19.8555 16.1135 16.1135 19.8555 11.5 19.8555C6.88652 19.8555 3.14453 16.1135 3.14453 11.5C3.14453 6.88652 6.88652 3.14453 11.5 3.14453Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EsdCompCircleIcon;
