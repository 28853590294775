import React, { useEffect, useState } from "react";
import "../../_dist/EsdCompEaDetailedBox.css";
import EsdCompRedirectTabs from "../tabs/EsdCompRedirectTabs";
import EsdCompEaHeader from "./EsdCompEaHeader";
import EsdCompUpLayeredSection from "./EsdCompUpLayeredSection";
import { EaTaBsStateType } from "../../data/Types";
import EsdCompCorporateMobile from "./EsdCompCorporateMobile";


// Define the props interface for EsdCompEaDetailedBox
interface EaDetailedBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: "board" | "corporate";
}

const EsdCompEaDetailedBox = ({ ...props }: EaDetailedBoxProps) => {
  //state for select tabs on board of management component--
  const [hasSelectedTab, setHasSelectedTab] =
    useState<EaTaBsStateType>("point");

   // this state is to set the hasSelectedTab state to point when the board component is rendred
    useEffect(() => {
      setHasSelectedTab("point")
    }, [props.variant==="board"])
    
  // switch the content of the board of mnagement component depending on tabs
  const boardContentSwitch = (tab: string) => {
    switch (tab) {
      case "point": // case of point variant--------------------
        return (
          <>
            <EsdCompUpLayeredSection className="desktop-version-ea-content"
              hasSelectedTab={hasSelectedTab}
              setHasSelectedTab={setHasSelectedTab}
              variant="Executive Solitude"
            />
            {/* <EsdCompBoardMobile className="mobile-version-ea-content" variant="point"/> */}
          </>
        );
      case "desire": //case of desire variant--------------
        return (
          <>
          <EsdCompEaHeader variant={props.variant} />
            <EsdCompUpLayeredSection className="desktop-version-ea-content"
              hasSelectedTab={hasSelectedTab}
              setHasSelectedTab={setHasSelectedTab}
              variant="Understanding Management & leadership"
            />
             {/* <EsdCompBoardMobile className="mobile-version-ea-content" variant="desire"/> */}
          </>
        );
      default:
        break;
    }
  };

  // switch the content depending on variants
  const contentSwitch = (variant: string) => {
    switch (variant) {
      case "board": // case of board variant--------------------
        return (
          <>
            <EsdCompRedirectTabs
              hasSelectedTab={hasSelectedTab}
              setHasSelectedTab={setHasSelectedTab}
            />
           
            {boardContentSwitch(hasSelectedTab)}
          </>
        );
      case "corporate": //case of corporate variant--------------
        return (
          <>
            <EsdCompEaHeader variant={props.variant} />
            <EsdCompUpLayeredSection className="desktop-version-ea-content" variant="Operational Territory" />
            <EsdCompCorporateMobile className="mobile-version-ea-content"/>
          </>
        );
      default:
        break;
    }
  };
  return (
    <section
      {...props}
      className={`ea-detailed-box-container ${
        props.className ? props.className : ""
      }`}>
      {contentSwitch(props.variant)}
    </section>
  );
};

export default EsdCompEaDetailedBox;
