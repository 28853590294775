import React, { useContext } from "react";
import"../_dist/EsdCompErathisMission.css"
import EsdCompUndelinedTitle from "./titles/EsdCompUnderlinedTitle";
import EsdCompComposedCard from "./cards/EsdCompComposedCard";
import { DataContext } from "../utils/SwitchedData";

interface ErathisMissioneProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompErathisMission = ({ ...props }: ErathisMissioneProps) => {
  // Access to the values data  that is passed as a value to the provider.
  const {data} = useContext(DataContext);
  return (
    <section
      {...props}
      className={`erathis-mission-container ${
        props.className ? props.className : ""
      }`}>
      <EsdCompUndelinedTitle>{data?.mission.h_1}</EsdCompUndelinedTitle>
      <EsdCompComposedCard variant={"without-overlay"} copyright={"Anton Repponen"}>
        <h1>{data?.mission.title}</h1>
        <p>
        {data?.mission.p}
        </p>
      </EsdCompComposedCard>
    </section>
  );
};

export default EsdCompErathisMission;
