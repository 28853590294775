import React, { useContext } from "react";
import EsdCompDivider from "../divider/EsdCompDivider";
import EsdCompHeroButton from "../buttons/EsdCompHeroButton";
import "../../_dist/EsdCompDescriptiveCard.css";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../utils/SwitchedData";
interface EsdCompDescriptiveCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}
const EsdCompDescriptiveCard = ({ ...props }: EsdCompDescriptiveCardProps) => {
  //use navigate---------------
  const navigate = useNavigate();
  //----------------------------
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  return (
    <div
      {...props}
      className={`esd-comp-descriptive-card-container ${
        props.className ? props.className : ""
      }`}>
      <h1>{data?.descriptiveCard.h_1}</h1>
      <EsdCompDivider
        line="horizontal"
        className="esd-comp-descriptive-card-divider"
      />
      <div>
        <p>{data?.descriptiveCard.p1}</p>
        <p>{data?.descriptiveCard.p2}</p>
        <p>{data?.descriptiveCard.p3}</p>
      </div>
      <EsdCompHeroButton
        onClick={() => navigate("/contact")}
        variant="transparent"
        className="esd-comp-descriptive-card-button">
        {data?.navBar.contact}
      </EsdCompHeroButton>
    </div>
  );
};

export default EsdCompDescriptiveCard;
