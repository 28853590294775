import React from "react";
import "../../_dist/EsdCompLanguageButton.css";
import EsdCompLanguageIcon from "../icons/EsdCompLanguageIcon";
interface NewDocButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
}

const EsdCompLanguageButton = ({ ...props }: NewDocButtonProps) => {
  return (
    <button
      {...props}
      className={`button-language ${props.className ? props.className : ""}`}
    >
      <EsdCompLanguageIcon className="button-language-icon" />
       <span>:</span>
      <span>{props.children}</span>
    </button>
  );
};

export default EsdCompLanguageButton;
