import React, { useContext } from "react";
import "../../_dist/EsdCompPanel.css";
import EsdCompInfoCard from "./EsdCompInfoCard";
import { DataContext } from "../../utils/SwitchedData";

interface PanelProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: "ses" | "spo";
}

const EsdCompPanel = ({ ...props }: PanelProps) => {
   // Access to the values data  that is passed as a value to the provider.
   const { data } = useContext(DataContext);

  // switch the content depending on variants
  const contentSwitch = (variant: string) => {
    switch (variant) {
      case "ses": // case of ses variant--------------------
        return (
          <div className="panel-list-cards">
            <EsdCompInfoCard>
             {data?.corporate.p_4}
            </EsdCompInfoCard>
            <EsdCompInfoCard>
            {data?.corporate.p_5}
            </EsdCompInfoCard>
            <EsdCompInfoCard>
            {data?.corporate.p_6}
            </EsdCompInfoCard>
          </div>
        );
      case "spo": //case of spo variant--------------
        return (
          <div className="panel-list-cards">
            <EsdCompInfoCard>
            {data?.corporate.p_8}
            </EsdCompInfoCard>
            <EsdCompInfoCard>
            {data?.corporate.p_9}
            </EsdCompInfoCard>
            <EsdCompInfoCard>
            {data?.corporate.p_10}
            </EsdCompInfoCard>
            <EsdCompInfoCard>
            {data?.corporate.p_11}
            </EsdCompInfoCard>
            <EsdCompInfoCard>
            {data?.corporate.p_12}
            </EsdCompInfoCard>
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div
      {...props}
      className={`panel-component-container ${
        props.className ? props.className : ""
      } panel-${props.variant}`}>
      {props.variant === "spo" && (
        <h3>
          {data?.corporate.p_7}
        </h3>
      )}
      <figure className="image-decoration decoration-top">
        <img src="../images/ea-decor-top.svg" alt="decoaration" />
      </figure>
      {contentSwitch(props.variant)}
      <figure className="image-decoration decoration-bottom">
        <img src="../images/ea-decor-bottom.svg" alt="decoaration" />
      </figure>
    </div>
  );
};

export default EsdCompPanel;
