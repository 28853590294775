import React, { useContext, useRef } from "react";
import "../../../_dist/EsdCompContactUs.css";
import EsdCompErathisLogo from "../../../components/logos/EsdCompErathisLogo";
import EsdCompDivider from "../../../components/divider/EsdCompDivider";
import EsdCompHeroButton from "../../../components/buttons/EsdCompHeroButton";
import EsdCompUnderlinedInput from "../../../components/inputs/EsdCompUnderlinedInput";
import EsdCompTextarea from "../../../components/inputs/EsdCompTextarea";
import GetTargetValue from "../../../utils/GetTargetValue";
import { DataContext } from "../../../utils/SwitchedData";
import EsdCompMessageAlert from "../../../components/EsdCompMessageAlert";
import ExecuteScrollComponent from "../../../utils/ExecuteScrollComponent";

// Custom type for input change events
type InputChangeEvent = React.ChangeEvent<HTMLInputElement>;
// Custom type for textarea change events
type TextAreaChangeEvent = React.ChangeEvent<HTMLTextAreaElement>;
//define the type of contact props
type Contact = {
  name: string;
  email: string;
  company: string;
  subject: string;
  message: string;
};
//define the interface properties----
interface ContactUsProps extends React.HTMLAttributes<HTMLFormElement> {
  id?: string;
  sendEmail: (e: React.FormEvent<HTMLFormElement>) => void;
  contact: Contact;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
  hasShowResult: boolean;
}
//----------------------------------------------------------------------------------------
const EsdCompContactUs = ({
  contact,
  hasShowResult,
  setContact,
  sendEmail,
  ...props
}: ContactUsProps) => {
  // Create a reference using the useRef hook--------------------------------
  const myRef = useRef<HTMLElement>(null);
  //-----------------------------------------------------------------------------
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  return (
    <form
      onSubmit={sendEmail}
      {...props}
      className={`contact-us-container ${
        props.className ? props.className : ""
      }`}>
      <main ref={myRef} className="form-content-container">
        <div className="inputs-side-container">
          <EsdCompErathisLogo className="contact-us-logo" variant="composed" />
          <h2>{data?.contactUs.h_2}</h2>
          <div className="input-side-content">
            <EsdCompUnderlinedInput
              type="text"
              placeholder={data?.contactUs.name}
              required
              value={contact?.name}
              name="name"
              onChange={(e: InputChangeEvent) => {
                setContact({
                  ...contact,
                  name: GetTargetValue(e),
                });
              }}
            />
            <EsdCompUnderlinedInput
              type="email"
              placeholder={data?.contactUs.email}
              required
              name="email"
              value={contact?.email}
              onChange={(e: InputChangeEvent) => {
                setContact({
                  ...contact,
                  email: GetTargetValue(e),
                });
              }}
            />
            <EsdCompUnderlinedInput
              type="text"
              placeholder={data?.contactUs.company}
              name="company"
              value={contact?.company}
              onChange={(e: InputChangeEvent) => {
                setContact({
                  ...contact,
                  company: GetTargetValue(e),
                });
              }}
            />
            <EsdCompUnderlinedInput
              type="text"
              placeholder={data?.contactUs.subject}
              name="subject"
              required
              value={contact?.subject}
              onChange={(e: InputChangeEvent) => {
                setContact({
                  ...contact,
                  subject: GetTargetValue(e),
                });
              }}
            />
          </div>
        </div>
        <div className="message-side-content">
          <header className="message-contact-underlined-title">
            <h1>{data?.contactUs.h_1}</h1>
            <EsdCompDivider
              className="message-contact-title-divider"
              line={"horizontal"}
            />
          </header>
          <EsdCompTextarea
            className="contact-textarea"
            placeholder={data?.contactUs.message}
            required
            name="message"
            value={contact?.message}
            onChange={(e: TextAreaChangeEvent) => {
              setContact({
                ...contact,
                message: GetTargetValue(e),
              });
            }}
          />
         
        </div>
      </main>
      <div className="alert-message-container">
            {hasShowResult && (
              <EsdCompMessageAlert>
                {data?.messageAlert.paragraph}
              </EsdCompMessageAlert>
            )}
          </div>

      <footer className="form-footer-container">
        <EsdCompDivider className="contact-us-divider" line={"horizontal"} />
        <EsdCompHeroButton className="send-button-contact" type="submit">
          {data?.contactUs.text_button}
        </EsdCompHeroButton>
      </footer>
      <ExecuteScrollComponent scrollRef={myRef}/>
    </form>
  );
};

export default EsdCompContactUs;
