import "../../_dist/EsdCompCarousselDot.css";

/* Props for the EsdCompCarousselDot component.*/
interface EsdCompCarousselDotProps {
  isActive: boolean;
  onClick: () => void;
}

const EsdCompCarousselDot: React.FC<EsdCompCarousselDotProps> = ({
  ...props
}: EsdCompCarousselDotProps) => {
  return (
    <span
      className={`dot-carousel ${props.isActive ? "active-dot" : ""}`}
      onClick={props.onClick}></span>
  );
};
export default EsdCompCarousselDot;
