import React, { useContext, useRef } from "react";
import "../../../_dist/EsdCompEaComponent.css";
import EsdCompLayeredButton from "../../../components/buttons/EsdCompLayeredButton";
import { useNavigate } from "react-router";
import ExecuteScrollComponent from "../../../utils/ExecuteScrollComponent";
import { DataContext } from "../../../utils/SwitchedData";

interface EaComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const EsdCompEaComponent = ({ ...props }: EaComponentProps) => {
  //use navigate---------------
  const navigate = useNavigate();
  //----------------------------
  // Create a reference using the useRef hook--------------------------------
  const myRef = useRef<HTMLElement>(null);
  //--------------------------------------------------------------------------
  // Access to the values data  that is passed as a value to the provider.
  const { data } = useContext(DataContext);
  return (
    <>
      <section
        ref={myRef}
        {...props}
        className={`ea-component-container ${
          props.className ? props.className : ""
        }`}
      >
        <EsdCompLayeredButton
          onClick={() => navigate("/board")}
          className="ea-layred-button"
          variant="board"
        >
          <span>{data?.executive.span_1}</span>
          <p>{data?.executive.p_1}</p>
        </EsdCompLayeredButton>
        <EsdCompLayeredButton
          onClick={() => navigate("/corporate")}
          className="ea-layred-button"
          variant="corporate"
        >
          <span>{data?.executive.span_2}</span>
          <p>{data?.executive.p_2}</p>
        </EsdCompLayeredButton>
      </section>
      <ExecuteScrollComponent scrollRef={myRef} />
    </>
  );
};

export default EsdCompEaComponent;
