import React from "react";
import "../../_dist/EsdCompComposedCard.css";
import ChildValidator from "../../utils/ChildValidator";

// Define the props interface for EsdCompComposedCard

interface EsdCompComposedCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  variant: "with-overlay" | "without-overlay";
  copyright: string;
}

const EsdCompComposedCard = ({ ...props }: EsdCompComposedCardProps) => {
  return (
    <section
      {...props}
      className={`composed-card-container composed-card-container-${
        props.variant
      }  ${props.className ? props.className : ""}`}
      title={`Photo by ${props.copyright ? props.copyright : ""} in unsplash`}>
      <div className="overlay-container-composed-card">
        <ChildValidator>
          {props.children}
        </ChildValidator>
      </div>
    </section>
  );
};

export default EsdCompComposedCard;
