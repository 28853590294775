import React from "react";
import"../../_dist/EsdCompErathisLogo.css"

interface ErathisLogoProps extends React.HTMLAttributes<HTMLImageElement> {
  variant: "simple" | "composed";
}

const EsdCompErathisLogo = ({ ...props }: ErathisLogoProps) => {


  return (
    <figure
      {...props}
      className={`erathis-logo-figure ${
        props.className ? props.className : ""
      } ${props.variant ? props.variant : ""}erathis-logo`}>
      {props.variant === "simple" ? (
        <img src="../images/erathisLogo.svg" alt="logo erathis" />
      ) : (
        <img src="../images/erathis-Logo.svg" alt="logo erathis" />
      )}
    </figure>
  );
};

export default EsdCompErathisLogo;
